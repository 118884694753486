.error-icon {
    margin-bottom: 20px;
}

.error-icon img {
    max-width: 80px;
}

.authorization-error-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
    max-width: 480px;
    width: 100%;
    margin: 0 auto;
}

.error-message-content {
    font-size: 16px;
    color: #000000;
    font-weight: 600;
    margin-bottom: 50px;
}

.back-login {
    margin-bottom: 20px;
}

.error-message-content-bottom {
    font-size: 16px;
    color: #000000;
    font-weight: 400;
}