.card-title {
    margin-bottom: 5px;
    font-size: 17px;
    font-weight: 500;
    /* font-family: poppins; */
}

.card-title a {
    color: #000 !important;
}

.card-subtitle {
    font-size: 13px;
    font-weight: 400;
    color: #7f7f7f;
}

.sub-sub-title {
    color: #7f7f7f;
    font-size: .8rem;
}

.card-corner {
    width: 18px;
    display: inline-block;
    height: 18px;
    position: absolute;
    left: 0;
    bottom: 0;
}

.card-corner.success {
    background: #6dd400;
}

.card-corner.warning {
    background: #f7b500;
}

.card-corner.danger {
    background: #e02020;
}

.card-corner.active {
    background: #104AA1;
}

.card-corner.inactive {
    background: #ADADAD;
}

.projectlist .card {
    /* max-width: 1200px; */
    border-radius: 5px;
    box-shadow: 2px 6px 15px -7px rgba(0, 0, 0, .2);
}

.projectlist .card-body {
    padding: 0.5rem 1rem;
}

.projectlist .card-title {
    /* margin-bottom: 16px; */
    color: #000;
    font-size: 1.25rem;
    font-weight: 500;
    width: 29%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 888;
    box-shadow: 2px 6px 15px -7px rgba(0, 0, 0, .2);
    border-radius: 5px;
    position: relative;
}

.projectlist .card-subtitle {
    margin-bottom: 16px;
    color: #7F7F7F;
    font-size: 1rem;
}

.projectList-comp {
    text-align: center;
}

.projectList-comp .comp-value {
    text-align: center;
    display: block;
    margin-bottom: 10px;
}

.projectList-comp .comp-label {
    text-align: center;
    display: block;
    color: #7F7F7F;
    font-size: 0.9rem;
    margin-top: 10px;
}

.projectList-comp .comp-value .red {
    color: #E02020;
}

.projectList-comp .comp-value .green {
    color: #92D050;
}

.projectList-comp .comp-value .yellow {
    color: #F7B500;
}

.project-stage {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    padding-right: 20px;
    margin-bottom: 20px;
    margin-top: 12px;
}

.project-stage li {
    list-style-type: none;
    margin: 0px;
    padding: 0px 20px 0px 5px;
    text-align: right;
    border-right: 2px solid #979797;
    line-height: 9px;
    color: #9EA0A5;
    font-size: .9rem;
    text-transform: uppercase;
}

.project-stage li:not(:last-child) {
    padding: 0px 20px 15px 5px;
}

.project-stage li:after {
    display: inline-block;
    width: 18px;
    height: 18px;
    content: " ";
    border-radius: 50%;
    margin-right: 4px;
    position: relative;
    background: #BFBFBF;
    left: 34px;
    border: 3px solid #BFBFBF;
}

.project-stage li.complete:after {
    background: #104AA1;
    border: 3px solid #104AA1;
}

.project-stage li.incomplete_after:after {
    background: #fff;
    border: 3px solid #adadad;
}

.project-filter .filtertext {
    line-height: 62px;
    vertical-align: bottom;
    display: inline-block;
    margin: 0px 15px 0px 0px;
}

.projectList-comp .progress-circle {
    font-size: 9px;
}

.doughnut-center-text {
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: .9rem;
    color: #AFAFAF;
    pointer-events: none;
    white-space: normal;
    word-break: break-word;
    line-height: 1.4;
    width: 150px;
}

.barchart-wrapper {
    height: 90px;
    display: block;
}

/* .barchart-wrapper canvas {
    height: 120px !important;
} */

.barchart-invert-wrapper {
    height: 75px;
    display: block;
}

/* .barchart-invert-wrapper canvas {
    height: 50px !important;
} */

.doghnut-wrapper {
    height: 147px;
    display: block;
    position: relative;
}

.charttitles {
    font-size: 0.95rem;
    color: #AFAFAF;
    text-align: center;
    font-weight: 400;
    z-index: 887;
    position: relative;
}

.mt-chart {
    margin-top: -1.50rem;
}