.participant-detail-container {
  width: 90% !important;
  max-width: 1280px !important;
}
.participant-detail-container-height {
  height: 640px !important;
}
.participant-detail-container .modal-body {
  padding: 15px 25px;
}
.participant-detail-container .react-bootstrap-table {
  padding-top: 0px !important;
  height: 450px;
  overflow-y: auto;
  overflow-x: auto;
}
.participant-detail-container nav {
  margin-bottom: 16px;
}
.participant-detail-container nav a {
  border: 0px !important;
  border-bottom: 1px solid #cccccc !important;
  color: #6b6c6f;
  font-size: 16px;
  font-weight: 400;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #1665d8;
  background-color: #f6f9fd;
  border-bottom: 2px solid #1665d8 !important;
}
.table_wrapper tr th,
.table_wrapper_modal tr th {
  padding: 0.75rem;
}
.participant-json-pretty .__json-pretty__ {
  margin: 0;
  height: 500px;
  overflow: auto;
}
.text-primary .set-color {
  color: rgba(0, 0, 0, 0.7);
}
.expanded-table {
  width: 100%;
}
.expanded-table tr th {
  /* position: static; */
}
.sessionId-color {
  color: #1665d8 !important;
  cursor: pointer;
}
.tx_id-color {
  color: #1665d8 !important;
  cursor: pointer;
}
.mobile-app-container .accordion__item + .accordion__item {
  border-top: none;
}
.mobile-app-container .accordion__button {
  padding: 10px 15px;
  border-radius: 3px;
  margin-top: 15px;
  background-color: #f6f9fd;
}
.mobile-app-container .accordion__button:hover {
  background-color: #f6f9fd;
}
.mobile-app-container-table {
  height: auto !important;
}
.accordion-expand {
  background-color: #f6f9fd;
  border: #1665d8;
}

.attempt-inner-accordia {
  background-color: #ffffff !important;
  font-size: 16px;
}
.attempt-inner-accordia:hover {
  color: #333 !important;
  background-color: #ffffff !important;
}
.table-image {
  width: 30px;
  height: 30px;
  object-fit: contain;
}
.table-image:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.mobile-app-container-table table thead tr th {
  position: static;
}
.mobile-app-container-table table tbody tr td {
  word-wrap: break-word;
}
.participant-table table thead tr th {
  position: static;
}
.participant-table table tbody tr td {
  word-wrap: break-word;
  white-space: break-spaces;
}
.textTruct {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.tooltip {
  z-index: 1151 !important;
}