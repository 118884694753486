.add-row-btn {
  padding: 7px 20px !important;
  font-size: 14px !important;
  font-weight: 700;
}
.add-row-btn span {
  margin-left: 5px;
}
.language-container {
  margin: 20px 0px;
  padding: 20px 0px;
  border-bottom: solid 1px #ddd;
}
.error {
  color: red;
}
.additional_configuration_accordion .accordion__panel .accordion__button {
  background: rgba(233, 236, 239, 0.57);
  color: #2f2b2c;
}
.additional_configuration_accordion
  .accordion__panel
  .accordion__button
  label.row-header-title {
  color: #2f2b2c;
}
.additional_configuration_accordion
  .accordion__panel
  .accordion__item
  .accordion__panel {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.additional_configuration_accordion
  .accordion__panel
  .accordion__button
  svg.fa-trash-alt {
  color: #7e7e7e;
}
.multi-language-container {
  margin: 20px 0px;
  display: flex;
  align-items: center;
}
.language-count-text {
  margin: 0px 15px;
  color: red;
}
.no-language {
  color: #9ea0a5;
}
