span[class^="result-filter-icon"] {
  color: #fff;
  margin-right: 0.5rem;
  font-size: 0.7rem;
  width: 25px;
  display: inline-block;
  text-align: center;
}

.custom_table_container span[class^="result-filter-icon"] {
  color: #ffff;
  margin-right: 0.3rem;
  font-size: 0.7rem;
  width: 50px;
  display: inline-block;
}

.custom_table_container .report-result-icon span[class^="result-filter-icon"] {
  height: 16px;
  vertical-align: middle;
  line-height: 15px;
}

.paper-table-container span[class^="result-filter-icon"] {
  color: #ffff;
  margin-right: 0.3rem;
  font-size: 0.7rem;
  width: 50px;
  display: inline-block;
}

.result-filter-icon_HR {
  background: #ff0000;
}

.result-filter-icon_R {
  background: #ff0000;
}

.result-filter-icon_AR {
  background: #92d050;
}

.result-filter-icon_PR {
  background: #ffca00;
}

.result-filter-icon_LR {
  background: #008000;
}

.result-filter-icon_G {
  background: #92d050;
}

.result-filter-icon_CM {
  background: #6758f3;
}

.result-filter-icon_AD {
  background: #1665d8;
}

.result-filter-icon_NC {
  background: #bcbcbc;
}

.result-filter-icon_DC {
  background: #6c6b6b;
}

.result-filter-icon_PD {
  background: #f2933d;
}

body .table span.result-filter-icon_NP {
  background-color: #ffffff;
  color: #000000 !important;
  border: 1px solid #000000;
}

.result-filter-icon_Incomplete {
  background: #bcbcbc;
}

.result-filter-icon_RJ {
  background: #737373;
}

.result-filter-label {
  margin-bottom: 0.5rem;
  display: inline-block;
  font-size: 0.9rem;
  color: #727376;
}

.upload-file-input.ac-upload-btn {
  width: 100%;
  height: 38px;
}

.folder-icon {
  position: absolute;
  right: 0;
  top: 8px;
  right: 10px;
  font-size: 22px;
}

.apply-btn {
  margin-top: 55px;
}

.question-label {
  margin-right: 0.3rem;
  font-size: 0.8rem;
  cursor: pointer;
  text-align: center;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tableicon {
  color: #0043ff;
}

.__react_component_tooltip.type-dark {
  color: #fff;
  text-transform: initial;
  padding: 4px 8px;
  max-width: 220px !important;
}

.pdfIcon {
  font-size: 1.3rem;
  color: #e30f0f;
}

.react-bootstrap-table table th {
  position: sticky;
  top: 0;
  background: #fff;
  border: 0;
  z-index: 1;
}

.react-bootstrap-table table thead {
  background: #fff;
}

.participant-table .react-bootstrap-table {
  padding-top: 0px !important;
}

.pdflinkdwd {
  font-size: 1.2rem;
  color: #c82333;
  cursor: pointer;
}

.mitigate-history.card {
  padding: 5px 10px;
  background-color: #dfecf6;
  box-shadow: 0px 3px 4px -2px rgba(0, 0, 0, 0.45);
  -moz-box-shadow: 0px 3px 4px -2px rgba(0, 0, 0, 0.45);
  -webkit-box-shadow: 0px 3px 4px -2px rgba(0, 0, 0, 0.45);
  color: #9ea0a5;
  font-size: 0.9rem;
}

.mitigate-history.card .text-blue {
  color: #1665d8;
}

.addmitigation {
  cursor: pointer;
  font-size: 18px;
}

.addmitigation.ismitigate {
  color: #0043ff;
}

.primary {
  background-color: #69b6f3;
}

.result-note-msg textarea {
  width: 100%;
  border: solid 1px #e4e8ee;
  padding: 10px;
  outline: none;
  border-radius: 5px;
  scrollbar-width: thin;
}

.result-note-msg textarea::-webkit-scrollbar {
  width: 0.5em;
}

.result-note-msg textarea::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}

.result-note-msg textarea::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

.edit-note-msg-btn {
  color: #6d7278;
}

.required-error {
  color: red;
}

.matigation-image {
  width: 18px;
}

.mitigationIcon {
  cursor: pointer;
}

.dropdown_template_risk_level_flag {
  display: flex;
  background-color: transparent !important;
}

.dropdown_template_risk_level_flag img {
  max-height: 20px;
  max-width: 33px;
  min-width: 33px;
  object-fit: contain;
}

.dropdown_template_risk_level_flag span {
  padding-left: 7px;
  top: 1px;
  position: relative;
}

.filter_result_row.multiselect_filter>div>div>div>div>div div {
  display: flex;
  align-items: center;
}

.filter_result_row.multiselect_filter>div>div>div.grouped_multi_select>div>div div {
  display: block;
}

.filter_result_row.multiselect_filter>div>div>div.grouped_multi_select>div>div>div>div:first-child {
  color: #000000;
  text-transform: capitalize;
  font-weight: 500;
}

.filter_project_row.multiselect_filter>div>div>div.grouped_multi_select>div>div div:hover {
  background-color: transparent;
}

.filter_result_row.multiselect_filter>div>div>div.grouped_multi_select>div>div>div>div:nth-child(2) {
  margin-left: 20px;
  background-color: transparent;
}

.filter_result_row.multiselect_filter>div>div>div.grouped_multi_select .prefix_grouped_multi_select__group-heading:hover {
  background-color: #deebff;
}

.filter_result_row.multiselect_filter>div>div>div.grouped_multi_select .prefix_grouped_multi_select__option:hover {
  background-color: #deebff;
}

.template_risk_level_flag_result_table img {
  max-width: 53px;
  max-height: 28px;
}

.template_risk_level_que_flag_result_table {
  display: inline-block;
  width: 50px;
  margin-right: 5px;
  vertical-align: middle;
}

.template_risk_level_que_flag_result_table img {
  max-width: 100%;
}