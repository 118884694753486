.checkbox-field-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.checkbox-field-container label{
    color: #9ea0a5;
    font-size: 15px;
    font-weight: 500;
    margin: 5px 0px;
    color: #212529;
}

.checkbox-field-container input{
  margin-right: 5px;
}
.email-notify .checkbox-label{
    margin: 5px 0px 0;
}
.checkbox-field{
    display: flex;
    justify-content: center;
    align-items: center;
}
.selectbox-container{
    color: #212529;
}

.selectbox-container label{
    color: #9ea0a5;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 18px;
    margin: 5px 0px;
}
.email-notify{
    position: relative;
    border: 1px solid #ddd;
    padding: 0 15px;
    margin-top: 20px;
    border-radius: 5px;
}

h5.email_notify_text {
    background: #fff;
    position: absolute;
    top: -22px;
    padding: 10px 15px 5px 10px;
}
.email-notify div#my-radio-group{
    margin-top: 15px;
    font-size: 14px;
}
.email-notify label.form-check-label input {
    margin-right: 8px;
    width: 15px;
    height: 15px;
    vertical-align: middle;
  }
  .email-notify label.form-check-label {
    margin: 10px 0;
    cursor: pointer;
    color: #212529;
  }
  span.radiodescription {
    display: block;
    padding-left: 23px;
    color: #9ea0a5;
    font-size: 12px;
    font-style: italic;
  }
  .d-flex.flex-row.result-notification-container {
    padding: 0px 15px;
  }