.admin-breadcrum {
    margin-right: 5px;
}

.admin-first-breadcrum {
    margin-left: 5px;
    margin-right: 12px;
}

.admin-tab {
    font-weight: inherit !important;
}

.admin-page-breadcrumb {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: .5px;
    color: #3E3F42;
}

.modal-dialog {
    max-width: 600px;
}

.save-modal-btn {
    width: 125px;
    margin: 10px;
}

.modal-box-text {
    margin: 20px !important;
}

button.save-btn.button_with_loader.btn.btn-primary {
    min-width: 140px;
    height: 50px;
}

.custom-loader.linesLoader.loader_inner_button {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
}

.custom-loader.linesLoader.loader_inner_button .spinner-border {
    width: 20px;
    height: 20px;
}

.result_conf_table_action_icon div {
    margin-right: 5px;
    padding: 0px 5px;
    cursor: pointer;
}

.result_conf_table_action_icon div:last-child {
    margin-right: 0px;
}

.result_conf_table_action_icon div img {
    max-width: 20px;
}

.tabs_modal .tab-content {
    max-height: 450px;
    overflow-y: auto;
    padding: 20px 15px;
}

.risklevel_col span[class^="filter_risk"] {
    vertical-align: middle;
}

.overrides_inner_content {
    position: relative;
    padding: 10px 0px 0 0px;
}

.nav-tabs.result_conf_tabs .nav-link {
    padding: 8px 0px;
    color: #6B6C6F;
    border: 0;
    margin-right: 32px;
}

.nav-tabs.result_conf_tabs .nav-item.show .nav-link,
.nav-tabs.result_conf_tabs .nav-link.active {
    color: #6B6C6F;
    background-color: transparent;
    border-bottom: 2px solid #1665d8 !important;
}

nav.result_conf_tabs.nav.nav-tabs {
    margin-top: 15px;
}

.template_icon_img img {
    max-height: 20px;
    max-width: 20px;
    margin-left: 10px;
    cursor: pointer;
}

.add-template-col {
    margin-bottom: 25px;
}

.template_type_input #template_name {
    font-size: 32px;
    background-color: #fff !important;
    padding: 0;
    border: none;
    border-bottom: 1px solid #a1a1a1;
    border-radius: 0;
}

.template_type_input input:-webkit-autofill {
    -webkit-background-clip: text;
}

.template_type_input #template_name[disabled] {
    border: none;
    opacity: 0.6;
}

.template_type_input input::-ms-input-placeholder {
    /* Edge 12-18 */
    font-size: 26px;
}

.template_type_input input::placeholder {
    font-size: 26px;
}

.modal_center_loader.custom-loader {
    min-height: 50vh;
    align-items: center;
    display: flex;
    justify-content: center;
}

.required-error.template_name_error_msg {
    position: absolute;
}

/*-------------Agent css------------*/


/* .add-tenant-row {
    margin-bottom: 25px;
    padding-right: 30px;
    margin-right: 0px;
}
.add-tenant-col {
    padding-right: 30px;
    margin-right: 0px;
}
/* form [class$="container"] {
    margin-bottom: 30px;
} */
/* .basic-multi-select {
    margin-bottom: 30px;
}
.agent-manage-table .agent-manage-table-row {
    cursor: pointer;
}  */