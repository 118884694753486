span.in-progress-download-link {
    font-size: 14px;
    color: #3E3F42;
    font-weight: 500;
}

.view-detail-icon {
    margin-left: 15px;
    visibility: hidden;
    opacity: 0;
}

.MuiDataGrid-cell:hover .view-detail-icon {
    visibility: visible;
    opacity: 1;
}

.custom-popover .MuiPaper-root {
    border-radius: 14px;
    max-width: 400px;
    min-width: 400px;
}

body .pp-header-row {
    padding: 18px 14px;
    align-items: center;
    justify-content: space-between;
}

.pp-title {
    font-size: 16px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 8px;
}

.pp-report-name {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
}

.pp-content {
    padding: 15px;
}

.date-range-section-popover {
    margin-bottom: 21px;
}

.attributes-section {
    margin-bottom: 10px;
}

ul.review-project-list {
    max-height: 86px;
    overflow: auto;
}

.attributes-section-list {
    margin-bottom: 0px;
    flex-wrap: wrap;
    max-height: calc(100vh - 576px);
    overflow: auto;
}

.pp-close {
    text-align: right;
}

span.failed-status {
    color: #d41d1d;
    font-weight: 500;
    font-size: 14px;
}

.attribute-list-tag {
    margin: 4px 10px 4px 0px;
    background: #E0EFFF;
    border-radius: 12px;
    padding: 2px 10px;
    font-size: 14px;
    color: #0169D9;
    font-weight: 500;
}

.attribute-list-tag:first-child {
    margin: 4px 10px 4px 0px;
}

span.not-available {
    color: gray;
    font-weight: 500;
    text-align: center;
}

.stepper-dialog-container {
    padding: 0px;
}

body .MuiDataGrid-row.Mui-selected {
    background-color: #E0EFFF;
    color: #0169D9;
}

body .stepper-dialog-close-button {
    position: absolute;
    top: 8px;
    right: 8px;
    color: #9e9e9e;
}

body .stepper-dialog-title {
    position: sticky;
    top: 0;
    background-color: #ffffff;
    z-index: 1;
    border-bottom: none;
    padding-top: 49px;
}

body .stepper-dialog-content {
    overflow-y: auto;
    min-height: 60vh;
}

body .stepper-dialog-actions {
    position: sticky;
    bottom: 0;
    background-color: #ffffff;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    z-index: 1;
    padding: 15px 24px;
}

.MuiFormControl-root.fullwidth-multiselect {
    width: 100%;
}

.attributes-selection {
    margin-bottom: 12px;
}

.attributes-select-deselect {
    margin-bottom: 20px;
}

.attributes-select-deselect span.MuiTypography-root {
    color: #000000;
    font-weight: 700;
    font-size: 16px;
}

.attributes-selection span.MuiTypography-root {
    color: #000000;
    font-weight: 400;
    font-size: 16px;
}

.content-max-width-stepper {
    max-width: 960px;
    margin: 0 auto;
}

.word-break {
    word-break: break-all;
}

body .stepper-dialog-wrapper {
    padding: 0;
}

.review-project-list li {
    list-style: none;
    position: relative;
}

.review-project-list li:before {
    content: "";
    height: 10px;
    width: 10px;
    position: absolute;
    left: -18px;
    background: #1977d3;
    border-radius: 50%;
    top: 7px;
}

/* .custom-step-icon {
    background: #ffffff;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    font-weight: 500;
    border: 1px solid #e7e7e7;
    position: relative;
    z-index: 1;
} */

/* .custom-step-icon.completed-step,
.custom-step-icon.current-step {
    background: #0169D9;
    color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border: 1px solid #0169D9;
} */

.custom-step-icon>div {
    height: 45px;
    width: 45px;
    border: 2px solid #e7e7e7;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-step-icon>div>div {
    background: transparent;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    font-weight: 500;
    border: 2px solid rgba(0, 0, 0, 0);
    position: relative;
    z-index: 1;
    text-align: center;
    font-size: 18px;
}

.custom-step-icon.current-step>div>div {
    background: #0169D9;
    color: #fff;
    border: 2px solid #0169D9;
}

.custom-step-icon.current-step>div {
    border: 2px solid #0169D9;
}

.custom-step-icon.completed-step>div {
    background: #0169D9;
    border: 2px solid #0169D9;
}

.custom-step-icon.completed-step>div>div {
    color: #fff;
    border: 2px solid #0169D9;
}

.custom-step-icon.current-step.completed-step>div>div {
    background: #0169D9;
    color: #fff;
    border: 2px solid #0169D9;
}

.custom-step-icon.current-step.completed-step>div {
    border: 2px solid #0169D9;
    background: transparent;
}

.MuiStepConnector-root.MuiStepConnector-horizontal {
    top: 20px;
    left: calc(-40% + 20px);
    right: calc(60% + 20px);
}

.MuiStepConnector-root.MuiStepConnector-horizontal.Mui-active span.MuiStepConnector-line,
.MuiStepConnector-root.MuiStepConnector-horizontal.Mui-completed span.MuiStepConnector-line {
    border-color: #0169D9;
}

.dialog-step-info {
    color: #757575;
    font-size: 14px;
    font-weight: 400;
}

.complete-step-icon {
    margin-right: 6px;
    margin-top: -3px
}

.date-range-section {
    background: #F6F6F6;
    border-radius: 12px;
    padding: 25px 35px;
    margin-top: 10px;
}

form .date-range-section label {
    color: #000000;
}

.date-range-section .date-picker-container {
    background: #ffffff;
}

.date-range-section .calendar_icon_wrapper svg {
    color: #000000;
}

.date-range-section .custom-datePicker-value {
    color: #000000;
    font-weight: 500;
    font-size: 16px;
}

.inline-box {
    display: inline-flex;
    vertical-align: middle;
    margin-left: 15px;
}

button.edit-icon-button-confirm-step {
    padding: 5px;
}

body .edit-icon-confirm-step {
    font-size: 18px;
    color: #757575;
}

body .edit-icon-button-confirm-step:hover .edit-icon-confirm-step {
    color: #1976d2;
}

.popover-container {
    background-color: #fff;
}

form label.attributes-selection,
form label.attributes-select-deselect {
    text-transform: none;
}