.react-datepicker-wrapper{
    cursor: pointer;
}
.custom-datePicker {
    padding: 5px;
    display: flex;
    gap: 5px;
    box-shadow: none;
    border: 1px solid #cccccc;
    outline: none !important;
    color: #515151;
    border-radius: 4px;
    align-items: center;
    min-height: 38px;
    font-size: 13px;
    position: relative;
}
.custom-datePicker .custom-datePicker-value {
    width: 100%;
}
.calendar_icon_wrapper {
    position: absolute;
    top: 9px;
    right: 10px;
}
.calendar_icon_wrapper svg {
    position: static;
}
.custom-datePicker label {
    margin: 0px;
    cursor: pointer;
}
button.react-datepicker__navigation.datePicker__custom__header {
    position: inherit;
    left: auto !important;
    right: auto !important;
    top: auto !important;
    bottom: auto !important;
}
select.datePicker__custom__header__select {
    padding: 5px 10px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    font-size: 13px;
    box-shadow: none !important;
    outline: none !important;
    cursor: pointer;
}
.react-datepicker__day--disabled:hover, 
.react-datepicker__month-text--disabled:hover, 
.react-datepicker__quarter-text--disabled:hover {
    cursor: not-allowed;
}