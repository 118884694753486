.outcome_traking_modal_height {
  min-height: 440px;
}

.outcome_traking_modal_height .loader {
  height: 440px;
}

.cancel-button {
  min-width: 140px;
  height: 50px;
  text-transform: uppercase;
}

.text-uppercase-irn {
  text-transform: capitalize !important;
}

.text-lowercase-irn {
  text-transform: lowercase;
}

textarea.form-control[name="additional_description"] {
  color: #495057;
}

.participant-action-icon {
  background: #f6f3f3;
  padding: 10px 12px;
  margin: 10px;
  border-radius: 5px;
  font-size: 22px;
}

.participant-action-icon img {
  width: 25px;
  height: auto;
  /* Maintain aspect ratio */
}

.participant-name {
  font-size: 18px;
}

.participant-phone {
  font-size: 14px;
  margin: 5px 0px;
}

.participant-phone img {
  margin-right: 5px;
}

.participant-email {
  font-size: 14px;
  margin: 5px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.participant-email img {
  margin-right: 5px;
}

.action-tab-img {
  margin: 0px 8px;
}

.custom-class .select__menu .select__menu-list {
  max-height: 395px;
}

.custom-class.particiant-status-dropdown .select__menu .select__menu-list {
  max-height: inherit;
}

.email_sms_sent_icon {
  display: flex;
  gap: 4px;
}

.participant-cell {
  overflow: hidden !important;
  white-space: normal !important;
  flex-direction: column;
}

.participant-formatter-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  line-height: normal;
  justify-content: center;
}