.navbar-expand-lg .navbar-nav .user-profile .nav-link {
  padding-top: 0px;
}

.navbar-expand-lg .navbar-nav .user-profile .dropdown-menu {
  right: 0px;
  left: auto;
}

.navbar-expand-lg .navbar-nav .user-notifications.nav-link {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  position: relative;
  font-size: 18px;
}

.navbar-expand-lg .navbar-nav .user-notifications.nav-link .badge {
  position: absolute;
  top: 0;
  left: 31px;
  font-size: 12px;
}

.dropdown-toggle::after {
  display: none;
}

.user-locale .dropdown-toggle {
  border: solid 1px #e2e5ed;
  background: #fff;
}

.user-locale .dropdown-menu {
  border: solid 1px #e2e5ed;
  min-width: 100%;
  padding: 0px;
}

.user-locale .dropdown-menu .dropdown-item {
  padding: 0.7rem 1.5rem;
}

.card-header {
  background-color: transparent;
  border: none;
  color: #3e3f42;
  font-family: Roboto;
  font-size: 18px;
}

.card {
  margin-bottom: 20px;

  border: solid 1px #eaedf3;
  background: #ffffff;
  box-shadow: 0px 1px3px rgba(0, 0, 0, 0.04);
}

.box {
  padding: 0rem 2rem;
}

.box:not(:last-child) {
  border-right: 1px solid #EAEDF3;
}

.box>span {
  color: #797d8a;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 18px;
  text-transform: uppercase;
}

.dashalert {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.dashalert .uikon {
  color: #4caaf0;
  font-size: 18px;
}

.dashalert li {
  color: #3e3f42;
  font-size: 14px;
  font-weight: 500;
  padding: 0.5rem 0.2rem;
  border-bottom: 1px solid #eaedf3;
}

.dashalert li .alertsub {
  color: #6b6c6f;
}

/*Switch CSS*/
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.riskdashboard-wrapper {
  height: 270px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.Interviewdata-wrapper {
  height: 270px;
}

#chartjs-tooltip {
  opacity: 1;
  position: absolute;
  background: #d8d8d8;
  color: #3e3f42;
  border-radius: 10px;
  -webkit-transition: all .1s ease;
  transition: all .1s ease;
  pointer-events: none;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  padding: 0px !important;
  margin: 0px;
  border: 2px solid #969696;
}

.chartjs-tooltip-key {
  display: inline-block;
  width: 10px;
  height: 10px;
}

#chartjs-tooltip .tooltiplabel {
  border-bottom: 2px solid #969696;
  margin: 0px;
  padding: 1px 25px;
  text-transform: uppercase;
  font-size: 0.7rem;
}

#chartjs-tooltip .tooltippercentage {
  float: right;
  padding: 0px 10px;
  background: #000000;
  color: #fff;
  margin: 4px 0px;
  border-radius: 4px;
  font-size: 0.8rem;
  margin-right: 4px;
}

#chartjs-tooltip .tooltipdata {
  font-size: 0.8rem;
  margin: 4px 0px;
  display: inline;
  float: left;
  margin-left: 4px;

}

.dashProjects .project-list-title {
  display: none;
}

.dashProjects .project-list {
  display: none !important;
}

.dashboard_doghnut_center_text {
  font-size: 14px;
  color: #AFAFAF;
  text-align: center;
  width: 150px;
  position: absolute;
  left: 50%;
  top: 44%;
  transform: translate(-50%, -50%);
}

.dahboardAlert-card.card {
  box-shadow: none;
  background: transparent;
}

.dahboardAlert-card .card-body {
  padding: 0;
}

.dahboardAlert-card .box {
  margin: 0 10px;
  background: #fff;
  padding: 15px;
  box-shadow: 2px 6px 15px -7px rgba(0, 0, 0, .2);
  border: none;
  border-radius: 5px;
  text-align: center;
}

.dashboard-card-tile .boxValue {
  color: #3e3f42;
  font-size: 36px;
  font-weight: 400;
  line-height: 38px;
}

.dashboard-card-tile .box span {
  font-size: 1rem;
  margin-top: 16px;
  display: block;
}

.dahboardAlert-card .box:last-child {
  margin-right: 15px;
}

.dahboardAlert-card .box:first-child {
  margin-left: 15px;
}

body .dashboard-card-tile {
  background: transparent;
  box-shadow: none;
}

body .dashboard-card-tile.MuiPaper-root.MuiCard-root .MuiGrid2-root.MuiGrid2-item.box.dashboard_tile_card {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  padding-bottom: 24px;
}

.tile_card {
  background: #fff;
  padding: 25px 10px;
  box-shadow: 2px 6px 15px -7px rgba(0, 0, 0, .2);
  border: none;
  border-radius: 5px;
  text-align: center;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.MuiGrid2-root.MuiGrid2-item.tile_card:first-child {
  margin-left: 0;
}

.tile_card>span {
  text-transform: uppercase;
  margin-top: 16px;
  display: block;
  color: #797d8a;
  font-size: 1rem;
  font-weight: 500;
  line-height: 18px;
}

body .dashboard-card-tile .custom-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

body .dashboard-card-tile .custom-loader span {
  margin: 0;
}