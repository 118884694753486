.MuiDataGrid-columnHeaders {
    background-color: #f7f7f7;
}

body .MuiDataGrid-columnHeaderTitle {
    font-weight: 500;
    text-transform: uppercase;
    color: #000;
}

.custom-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.MuiDataGrid-root .MuiDataGrid-row .Mui-hovered {
    background-color: transparent;
}

.custom_table_container .MuiDataGrid-cellContent {
    overflow: visible;
    white-space: break-spaces;
    text-overflow: inherit;
}

.MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell {
    overflow: hidden;
}

body .MuiDataGrid-columnHeader:focus,
body .MuiDataGrid-cell:focus {
    outline: none !important;
}

body .pagination-footer-text {
    margin-left: 15px;
}

/* Enhanced Table css start here  */
.paper-table-container {
    height: 500px;
    overflow-y: auto;
}

.paper-table-container table thead {
    position: sticky;
    top: -1px;
    z-index: 9;
}

.paper-table-container table tbody {
    position: relative;
    z-index: 1;
}

.paper-table-container table thead tr th {
    text-transform: uppercase;
    color: #000;
    background-color: #f7f7f7;
    font-weight: 500;
}

.paper-table-container table tbody tr td {
    vertical-align: middle;
    color: #6d7278;
}

.custom-pagination .MuiTablePagination-selectLabel,
.custom-pagination .MuiTablePagination-displayedRows {
    margin-top: 10px;
}

body .table-footer-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #e0e0e0;
}

.table-sorting-icon .MuiSvgIcon-root {
    width: 18px;
    height: 17px;
    object-fit: cover;
    margin-left: 3px;
    margin-bottom: 3px;
    color: #000
}

.MuiDataGrid-sortIcon {
    color: #000
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon {
    opacity: 1;
    color: #aaa6a6;
}

.table-sorting-hover-icon .MuiSvgIcon-root {
    width: 18px;
    height: 17px;
    object-fit: cover;
    margin-left: 3px;
    margin-bottom: 3px;
    opacity: 1;
    color: #aaa6a6;
}

.table-spinner-container,
.table-empty-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #6d7278;
}

.table-spinner-container {
    height: calc(500px - 141px);
}

.table-empty-container {
    height: 150px;
}


.MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-cell:focus-within {
    outline: none !important
}

body .MuiDataGrid-columnHeader {
    height: 48px !important;
}

.MuiPaper-root td span.MuiCheckbox-root.checkbox-center-align {
    padding: 0px;
}

.paper-table-container table.table-scroll-enabled tbody tr td.right-border:after,
.paper-table-container table.table-scroll-enabled thead tr th.right-border:after,
.paper-table-container table.table-scroll-enabled tbody tr td.left-border:after,
.paper-table-container table.table-scroll-enabled thead tr th.left-border:after {
    content: "";
    position: absolute;
    right: 0;
    border-right: 1px solid #e7e7e7;
    height: 100%;
    top: 0;
}

.paper-table-container table.table-scroll-enabled tbody tr td.left-border:after,
.paper-table-container table.table-scroll-enabled thead tr th.left-border:after {
    left: 0;
    right: auto;
}

.table-row-cursor-selectable .MuiDataGrid-row {
    cursor: pointer;
}

body .app-table .MuiDataGrid-row--borderBottom[role="row"] {
    background: inherit;
}

.MuiDataGrid-columnSeparator.MuiDataGrid-columnSeparator--resizable.MuiDataGrid-columnSeparator--sideRight {
    display: none;
}