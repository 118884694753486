.tenant-page-title {
  color: #3e3f42;
  border-bottom: 1px solid #cccc;
  padding: 1rem 0rem;
  margin-bottom: 50px;
  margin-top: 50px;
  font-size: larger;
  font-weight: bold;
}

.tenant-add-button {
  color: #34aa44;
  float: right;
  cursor: pointer !important;
}

.tenant-cross-button {
  color: #dc3545;
  float: right;
  cursor: pointer !important;
}

.required:after {
  content: " *";
  color: red;
  font-size: x-small;
  position: absolute;
  margin-left: 5px;
}

.required[data-permission='true']:after {
  content: '\2713';
  color: #0169D9;
  font-size: x-small;
  position: absolute;
  margin-left: 5px;
}

.business-line-field[data-permission='true']:after {
  content: '\2713';
  color: #0169D9;
  font-size: x-small;
  position: absolute;
  margin-left: 5px;
}

.tenant-manage-table-pagi-text {
  margin-left: 10px;
}

.tenant-manage-table {
  color: #9b9b9b;
}

.tenant-manage-table-header {
  border-top-width: 0 !important;
  font-weight: inherit;
  color: #757575;
}

.tenant-manage-table-col-1 {
  text-decoration: underline;
  /* border-bottom: 1px solid #dee2e6; */
  cursor: pointer;
}
.tenant-manage-table-col-2 {
  border-bottom: 1px solid #dee2e6;
}

.tenant-manage-table-row {
  cursor: pointer !important;
}

.addTenant-table td,
th {
  padding: 5px;
}
.addTenant-save-button {
  float: right;
  margin: 10px;
  width: 100px;
  background-color: #027cff;
  color: white;
}
.addTenant-cancel-button {
  float: right;
  margin: 10px;
  width: 100px;
  background-color: #82868a;
  color: white;
}

.tenant-add-button a {
  color: #34aa44;
}

#title {
  text-align: center;
  font-family: arial, sans-serif;
}

#tenants {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 30px;
}

#tenants td {
  border-bottom: 1px solid #ddd;
  padding: 10px;
}

#tenants th {
  border-bottom: 1px solid #ddd;
  padding: 8px;
}
.invalid-feedback {
  display: block;
}

.add-tenant-validation {
  font-size: smaller;
  margin-bottom: -10px;
}
/* 
.add-tenant-input{
  margin-bottom: 30px;
} */

#tenants tr:nth-child(even) {
  background-color: #f2f2f2;
}

#tenants tr:hover {
  background-color: #ddd;
}

#tenants th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
}

.add-tenant-row {
  margin-bottom: 25px;
  margin-right: 30px;
}

.add-tenant-col {
  margin-right: 30px;
}

.js .inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  max-width: 80%;
  font-size: 1.25rem;
  /* 20px */
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: 0.625rem 1.25rem;
  /* 10px 20px */
}

.no-js .inputfile + label {
  display: none;
}

.inputfile:focus + label,
.inputfile.has-focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label * {
  /* pointer-events: none; */
  /* in case of FastClick lib use */
}

.inputfile + label svg {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
  margin-top: -0.25em;
  /* 4px */
  margin-right: 0.25em;
  /* 4px */
}

/* style 6 */

.inputfile-6 + label {
  color: #d3394c;
}

.inputfile-6 + label {
  border: 1px solid #d3394c;
  background-color: #f1e5e6;
  padding: 0;
}

.inputfile-6:focus + label,
.inputfile-6.has-focus + label,
.inputfile-6 + label:hover {
  border-color: #722040;
}

.inputfile-6 + label span,
.inputfile-6 + label strong {
  padding: 0.625rem 1.25rem;
  /* 10px 20px */
}

.inputfile-6 + label span {
  width: 200px;
  min-height: 2em;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: top;
}

.inputfile-6 + label strong {
  height: 100%;
  color: #f1e5e6;
  background-color: #d3394c;
  display: inline-block;
}

.inputfile-6:focus + label strong,
.inputfile-6.has-focus + label strong,
.inputfile-6 + label:hover strong {
  background-color: #722040;
}

@media screen and (max-width: 50em) {
  .inputfile-6 + label strong {
    display: block;
  }
}
.custom-tenant-loader {
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
