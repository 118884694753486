/* Project Team css */
.modal-header{
    padding: 1rem 1rem;
}
.modal-title{
    font-size: 1rem;
    line-height: 1rem;
}
.table_wrapper p, .table_wrapper_modal p{
    margin-bottom: 0px;
}
.table_wrapper {
    background-color: #fff;
    -webkit-box-shadow: 0px 1px 2px 2px rgba(214,214,214,0.3);
-moz-box-shadow: 0px 1px 2px 2px rgba(214,214,214,0.3);
box-shadow: 0px 1px 2px 2px rgba(214,214,214,0.3);
}
.table_wrapper_modal{
 border: 1px solid #D8D8D8;
 border-radius: 6px;
}
.table_wrapper tr th, .table_wrapper_modal tr th{
    text-transform: uppercase;
    font-size: 0.8rem;
    color: #757575;
    padding-top: 30px;
    font-weight: 400;
}
.table_wrapper tr td, .table_wrapper_modal tr td{
vertical-align: middle;
color: #6d7278;}

.table_wrapper p.profilename{
    margin-left: 30px
}