.outcome_traking_modal_height {
  max-height: 580px;
  min-height: 1px;
}

.outcome_traking_modal_height .loader {
  height: 370px;
}

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-amount {
  height: 38px !important;
}

.font-15 {
  font-size: 15px !important;
}

h6 {
  margin: 0;
}

hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.text-uppercase {
  text-transform: uppercase;
}

.mitigate-history-column .mitigate-history.card:last-child {
  margin-bottom: 0;
}