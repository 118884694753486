.result_loader {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.format-irn {
  color: #0056b3;
  cursor: pointer;
}

.format-irn:hover {
  text-decoration: underline;
  color: #0056b3;
  cursor: pointer;
}

.pdfLoaderMsg {
  text-align: center;
  margin-top: 10px;
}

.result_resetFilter-btn {
  color: #515151;
  cursor: pointer;
  font-size: 13px;
  margin-top: 4px;
  width: max-content;
}

.flex-language14 {
  flex: 0 0 12%;
  padding-left: 0;
  min-width: 128px;
  padding-right: 5px;
}

.flex14 {
  flex: 0 0 12%;
  padding-left: 0;
  min-width: 123px;
  padding-right: 5px;
}

.flex18 {
  flex: 0 0 15%;
  padding-left: 0;
  min-width: 138px;
  padding-right: 5px;
}

.participants-page .flex30 {
  flex: 0 0 20%;
}

.participants-page .flex70 {
  flex: 0 0 80%;
}

.flex9 {
  flex: 0 0 9%;
  padding-left: 0;
  padding-right: 0;
}

.result_filter_section_new .flex20 {
  flex: 0 0 18%;
  padding-left: 0;
  min-width: 130px;
  padding-right: 5px;
}

.result_filter_section_new .flex12 {
  flex: 0 0 13.7%;
  min-width: 90px;
  padding-left: 0;
  padding-right: 5px;
}

.submit-button-container {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.select__indicator-separator {
  display: none;
}

.select__indicator svg {
  width: 14px;
  height: 14px;
  color: #000000;
}

body .select__placeholder {
  color: #515151;
  font-size: 13px;
}

.result-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.result-apply-button {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.review-modal-title {
  font-weight: 700;
  color: #1e68d5;
  font-size: 18px;
}

.review-list {
  height: 13px;
  width: 13px;
  background-color: #1e68d5;
  border-radius: 10px;
  margin: 5px;
}

.review-modal-text {
  font-weight: 400;
  font-size: 16px;
  color: #0f0f0f;
}

.cancel_button {
  color: #0f0f0f;
}

.np-modal-text {
  font-weight: 400;
  font-size: 16px;
  color: #fe1414;
}

.cm_modal_container .modal-content {
  width: 350px;
}

.cm_modal_container .modal-dialog {
  max-width: 300px;
}

.hide_reviewed_irn_label {
  color: #A1A1A1;
  font-weight: 400;
  font-size: 16px;
  margin-right: 8px;
}

.question-sort-icon {
  margin-left: 7px;
}

/*-----------*/

.outcome_traking_modal_height {
  min-height: 440px;
}

.outcome_traking_modal_height .loader {
  height: 440px;
}

.cancel-button {
  min-width: 140px;
  height: 50px;
  text-transform: uppercase;
}

.text-uppercase-irn {
  text-transform: capitalize !important;
}

.text-lowercase-irn {
  text-transform: lowercase;
}

textarea.form-control[name="additional_description"] {
  color: #495057;
}

.participant-action-icon {
  background: #f6f3f3;
  padding: 10px 12px;
  margin: 10px;
  border-radius: 5px;
  font-size: 22px;
}

.participant-action-icon img {
  width: 25px;
  height: auto;
  /* Maintain aspect ratio */
}

.participant-name {
  font-size: 18px;
}

.participant-phone {
  font-size: 14px;
  margin: 5px 0px;
}

.participant-phone img {
  margin-right: 5px;
}

.participant-email {
  font-size: 14px;
  margin: 5px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.participant-email img {
  margin-right: 5px;
}

.action-tab-img {
  margin: 0px 8px;
}

.custom-class .select__menu .select__menu-list {
  max-height: 395px;
}

.custom-class.particiant-status-dropdown .select__menu .select__menu-list {
  max-height: inherit;
}

.email_sms_sent_icon {
  display: flex;
  gap: 4px;
}

.participant-cell {
  overflow: hidden !important;
  white-space: normal !important;
  flex-direction: column;
}

.result-selection-header .MuiDataGrid-columnHeaderTitle {
  margin-left: 9px;
}

.result_lang_head_col {
  position: relative;
}

.result_lang_col_fix {
  position: relative;
}

.result-hold-label .MuiFormControlLabel-label {
  font-size: 16px;
  font-weight: 600;
}