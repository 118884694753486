#overlay {
  position: fixed;
  /* Sit on top of the page content */
  display: none;
  /* Hidden by default */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Black background with opacity */
  z-index: 999999;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  /* Add a pointer on hover */
}

.moa {
  margin: 0 auto;
}

._loading_overlay_overlay {
  height: 88% !important;
  z-index: 9;
}

._loading_overlay_spinner svg circle {
  stroke: #1665d8;
}

._loading_overlay_content {
  margin: 10% auto !important;
}

._loading_overlay_content .spinner-border {
  border-width: 2px;
}

#topbar {
  background: #ffffff;
  /* box-shadow: 1px 1px 3px rgba(0,0,0,0.5); */
  box-shadow: none;
  /* z-index: 9999; */
}

#topbar .navbar {
  background: #fff;
  /* padding: 0rem 1rem; */
  -webkit-box-shadow: 2px 6px 15px -7px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px 6px 15px -7px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 6px 15px -7px rgba(0, 0, 0, 0.2);
  z-index: 99;
  position: fixed;
  width: 100%;
}

.navbar-brand {
  width: 300px;
  height: 50px;
}

.navbar-brand img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: left;
}

.sidebar {
  height: calc(100vh - 71px);
  top: 0px;
  width: 50px;
  display: block;
  z-index: 999;
  color: #fff;
  font-weight: 200;
  /* transition-duration: .2s, .2s, .35s; */
  box-shadow: 2px 6px 15px -7px rgba(0, 0, 0, 0.2);
  position: fixed;
  margin-top: 71px;
  background: #fff;
  left: 0px;
}

.sidebar.open {
  width: 250px;
}

.buttontoggle {
  margin-right: -15px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.sidebar .sidebar-wrapper {
  box-shadow: 0px 1px3px rgba(0, 0, 0, 0.1);
  height: calc(100vh -40px);
}

.sidebar-wrapper a {
  padding: 0.8rem 1.2rem;
  text-align: left;
  font-size: 1.125rem;
  color: #6d7278;
  border-right: 3px solid transparent;
  font-weight: 500;
}

.sidebar-wrapper a i {
  color: #9ea0a5;
}

.sidebar-wrapper a.active {
  background-color: #f6f9fd;
  color: #1665d8;
  border-left: 4px solid #1665d8;
  padding: 0.8rem 0.99rem;
  width: 50px;
}

.sidebar.open .sidebar-wrapper a.active {
  width: 250px;
}

.sidebar-wrapper a.active i {
  color: #1665d8;
}

.sidebar-wrapper a:hover {
  text-decoration: none;
}

.bottom_logo {
  position: absolute;
  bottom: 0px;
  color: #333333;
}

.arrow_btn {
  width: 0.845em !important;
}

.main-panel {
  position: relative;
  float: right;
  width: calc(100% - 50px);
  min-height: 100%;
  /* overflow: auto; */
  background-color: #fafafc;
  padding: 30px 15px 0px 15px;
  margin-left: 50px;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.main-panel>.content {
  padding: 10px;
}

.sidebar .sidebar-text {
  display: none;
}

.sidebar.open .sidebar-text {
  display: inline-block;
}

.sidebar.open+.main-panel {
  width: calc(100% - 250px);
  margin-left: 250px;
  min-height: calc(100vh - 50px);
}

.main-panel .content {
  padding-top: 70px;
}

.navbar-expand-lg .navbar-nav .user-profile .nav-link {
  padding-top: 0.25rem;
}

.navbar-expand-lg .navbar-nav .user-profile .dropdown-menu {
  right: 0px;
  left: auto;
  z-index: 999;
}

.navbar-expand-lg .navbar-nav .user-notifications.nav-link {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.dropdown-toggle::after {
  display: none;
}

.project-filter.sm .filtertext {
  line-height: 35px;
  vertical-align: bottom;
  display: inline-block;
  /* margin: 0px; */
  margin: 0px 10px 0px 10px;
}

.project-filter.sm .switch {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 22px;
  outline: none;
  margin: 0px 9px;
  margin-bottom: 0;
}

.project-filter.sm .slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.project-filter.sm input:checked+.slider:before {
  transform: translateX(20px);
}

.switch {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 28px;
  outline: none;
}

.switch input {
  position: absolute;
  top: -99999px;
  left: -99999px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 28px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 2px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked+.slider {
  background-color: #48a932;
}

input:focus+.slider {
  box-shadow: 0 0 1px #48a932;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.project-info-tab {
  border-bottom: 1px solid #cccccc;
  margin-bottom: 16px;
  position: relative;
}

.project-info-tab a {
  color: #6b6c6f;
  /* padding: 15px 20px; */
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 400;
  position: relative;
}

.project-info-tab a.active:after {
  content: "";
  color: #333333;
  border-bottom: 2px solid #1665d8;
  font-weight: 600;
  width: 46%;
  position: absolute;
  left: 27%;
  bottom: 0;
}

/*Custom Radio Button*/
.custom-radio {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  line-height: 15px;
  font-size: 1rem;
  -webkit-user-select: none;
  user-select: none;
}

.custom-radio+.custom-radio {
  margin-left: 5px;
}

/* Hide the browser's default radio button */
.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.custom-radio:hover input~.checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */

.custom-radio input:checked~.checkmark {
  background-color: #28a745;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.custom-radio input:checked~.checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
/* .custom-radio .checkmark:after {
     top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #28a745;
  } */
.questionlist-sort {
  margin: 0px;
  padding: 0px;
}

.questionlist-sort1 {
  margin: 0px;
  padding: 0px;
}

.show-questiontype span {
  background: #C2D3EC;
  padding: 10px;
  color: #1665D8;
  font-weight: 500;
  min-width: 108px;
  display: block;
  text-align: center;
}

.show-questiontype {
  position: absolute;
  right: 20px;
  top: -7px;
}

.questionlist-sort1 .add-new-question {
  padding: 15px 15px;
  background: #fff;
  box-shadow: 2px 6px 15px -7px rgb(0 0 0 / 20%);
  cursor: all-scroll;
  border-radius: 5px;
  /* border: 1px solid #e6e6e6; */
  /* width: calc(100% - 54px); */
  float: left;
  margin: 0px;
  margin-bottom: 15px;
  width: 100%;
}

/* .questionlist-sort li::after {
    width: 40px;
    height: 105px;
    content: "";
    position: absolute;
    top: 0;
    right: -40px;
} */
.ques-text {
  display: inline-block;
  width: calc(100% - 100px);
}

.questionlist-sort1 .ques-text textarea {
  border: 1px solid #d2d2d2;
}

.ques-text textarea {
  border: none;
  width: 100%;
  resize: none;
  padding: 5px 10px;
  font-size: 1.125rem;
  color: #3e3f42;
  overflow: hidden;
}

p.questionpara {
  padding: 5px 10px;
  font-size: 1.125rem;
  margin-bottom: 0;
  cursor: text;
  word-break: break-all;
  min-height: 100px;
}

p.questionpara pre {
  white-space: break-spaces;
  font-size: 1.125rem;
  width: 100%;
  font-family: inherit;
  color: #3e3f42;
  word-break: break-word;
}

.question-list {
  list-style-type: none;
  margin: 0px;
}

.payloaddata {
  overflow: hidden;
}

.editableContainer .styles_Editext__button__6H8n_ {
  border: none;
  padding: 0;
  margin-bottom: 2px;
}

div[editext="view-container"] {
  color: #30304d;
  font-size: 16px;
  text-transform: inherit;
  font-weight: normal;
}

input[editext="input"] {
  width: 100%;
  border: 1px solid #e4e8ee;
  padding: 0.6em;
  outline: none;
  height: 38px;
  margin-bottom: 3px;
  font-size: 14px;
  border-radius: 0.25rem;
  color: #495057;
}

.roleselect .basic-multi-select {
  margin-bottom: 0;
  font-size: 14px;
}

.payloaddata pre {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  white-space: break-spaces;
  height: 120px;
  overflow: hidden;
  margin-bottom: 0;
}

.rowdata.payloaddata pre {
  -webkit-line-clamp: 2;
  height: 44px;
}

.configuration-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.configuration-file-input,
.custom-upload {
  width: 100%;
  height: 40px;
  overflow: hidden;
  outline: none;
}

.modal-footer {
  border: none;
  padding: 0;
}

.bluecustom-btn {
  background-color: #58a8fe !important;
  border: 1px solid #58a8fe !important;
  border-radius: 3px !important;
  width: 100%;
}

.bluecustom-btn.btn {
  padding: 8px 0;
}

.configuration-file-input::before {
  content: attr(data-content);
  display: inline-block;
  background: #58a8fe;
  border: 1px solid #58a8fe;
  width: 100%;
  border-radius: 3px;
  outline: none;
  color: #fff;
  cursor: pointer;
  font-weight: 700;
  font-size: 12px;
  min-height: 38px;
  line-height: 38px;
  text-align: center;
}

.configuration-file-input.automated::before {
  content: attr(data-content);
}

.configuration-file-input.upload-xlsx::before {
  content: attr(data-content);
}

.configuration-file-input.upload-zip::before {
  content: attr(data-content);
}

.supervisormsg {
  display: block;
  text-align: center;
  font-size: 14px;
  margin-top: 5px;
}

.supervisormsg .error {
  color: #d8000c;
}

.supervisormsg .success {
  color: #4f8a10;
}

/* .confirm-box{margin-top:150px} */
.approvedetail {
  background-color: #def0fc;
  padding: 20px 90px;
  color: #3e3f42;
  margin-bottom: 25px;
  font-weight: 500;
}

.payloadmodaltext {
  word-break: break-all;
  background: black;
  color: #fff;
  padding: 30px 15px;
}

.question-list .question-list-row {
  padding: 15px 15px;
  background: #fff;
  /* border: 1px solid #e6e6e6; */
  box-shadow: 2px 6px 15px -7px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  cursor: all-scroll;
  /* width: calc(100% - 54px); */
  width: 100%;
  float: left;
  margin: 0px;
  margin-bottom: 1rem;
}

.ques-sequence {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: inline-block;
  background: #8dc8f6;
  text-align: center;
  vertical-align: top;
  font-size: 1.875rem;
  line-height: 3.1rem;
  color: #fff;
  margin-right: 10px;
}

.ques-sequence.active {
  background: #69b6f3;
  color: #fff;
}

.ques-sequence.inactive {
  background: #b6b7bb;
  color: #fff;
}

.ques-expanswer {
  margin-right: 15px;
}

.ques-expanswer span.underline {
  border-bottom: 1px solid #b1b1b1;
}

.question-list-row .label {
  font-weight: 500;
  display: block;
}

.delete-question {
  /* width: 50px;
     height: 106px; 
     float: left;
    padding: 20px 0px; */
}

.delete-question .uikon {
  font-size: 0.7rem;
}

.tables-control-menu {
  /* font-size: 14px; */
  color: #104aa1;
  font-weight: 500;
  padding: 4px 0px;
}

/* .tables-control-menu > div {
  line-height: 15px;
} */
.tables-control-menu>div {
  width: 50%;
}

.tables-control-menu img {
  margin-right: 8px;
}

.table-fixed {
  table-layout: fixed;
}

.table-fixed td,
.table-fixed th {
  word-break: break-word !important;
}

.react-bootstrap-table {
  /* max-height: none;
  height: unset; */
  padding-top: 0px !important;
}

.react-bootstrap-table table th {
  outline: none !important;
}

.react-bootstrap-table table th.sortable:active,
.react-bootstrap-table table th.sortable:focus {
  outline: none !important;
  border-bottom: 1px solid #1665d8;
}

.divider {
  z-index: 5;
  width: 1px;
  height: 38px;
  background: #eaedf3;
  margin: 0px 10px;
}

.participants-page .card {
  margin-bottom: 1rem;
}

.card {
  box-shadow: 2px 6px 15px -7px rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 5px;
}

body .card-title.h5 {
  box-shadow: none;
}

.participants-page .card-header {
  padding: 0.4rem 1rem 0rem 1rem;
  border-bottom: 1px solid #eaedf3;
  font-size: 16px;
  font-weight: 600;
}

.participants-page .card-body {
  padding: 1rem;
}

.download-btn,
.upload-btn {
  cursor: pointer;
}

.upload-file-input {
  width: 100%;
  /* visibility: hidden; */
  position: absolute;
  /* padding: 10px 0px; */
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  z-index: 999;
  height: 35px;
}

.upload-file-input::-webkit-file-upload-button {
  cursor: pointer;
}

.ac-upload-btn {
  width: 100%;
}

.export-btn {
  cursor: pointer;
}

.btn.btn-success-upload {
  padding: 10px 15px;
  background-image: linear-gradient(0deg, #34aa44 0%, #38b249 100%);
  border: 1px solid #2d9c3c;
  box-shadow: 0 1px 1px 0 rgba(19, 31, 21, 0.1),
    inset 0 2px 0 0 rgba(255, 255, 255, 0.06);
}

.btn.btn-error-upload {
  padding: 10px 15px;
  background-image: linear-gradient(180deg, #e73c1e 0%, #cf3014 100%);
  border: 1px solid #ba0b15;
  box-shadow: 0 1px 1px 0 rgba(43, 26, 24, 0.1),
    inset 0 2px 0 0 rgba(255, 255, 255, 0.08);
}

.upload-file-modal .modal-content {
  padding: 15px;
}

.upload-file-modal .span-column {
  width: 110px;
}

.upload-file-modal a.file-link {
  font-size: 18px;
  color: #0043ff;
  letter-spacing: -0.34px;
  font-weight: 500;
}

.upload-file-modal .error-span {
  padding-left: 25px;
}

[data-letters]:before {
  content: attr(data-letters);
  display: inline-block;
  font-size: 1.5em;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  text-align: center;
  border-radius: 50%;
  background: plum;
  vertical-align: middle;
  margin-right: 1em;
  color: white;
}

.question-note {
  color: #9fa1a6;
  display: block;
  float: left;
  padding-right: 50px;
  text-align: justify;
}

.particiant-controls {
  margin-left: -10px;
  margin-right: -10px;
}

.particiant-controls>div:nth-child(1) {
  width: 22%;
}

.particiant-controls>div:nth-child(2) {
  width: 45%;
}

.particiant-controls>div:nth-child(3) {
  width: 32%;
}

.particiant-controls>div:nth-child(4) {
  width: 10%;
}

.particiant-controls>div {
  padding-left: 10px;
  padding-right: 0px;
}

.modal-header .close {
  outline: none;
}

.upload-modal-header {
  border-bottom: none;
  font-size: 16px;
}

.upload-modal-header .modal-title {
  font-size: 22px;
  font-weight: 400;
}

.right-iconmenus {
  margin-top: -55px;
  display: inline-block;
  float: right;
}

.iconmenus {
  text-transform: uppercase;
  color: #0069d9;
  padding: 0 0.8rem;
  font-size: 0.9rem;
  z-index: 1;
}

.iconmenus label.csv-label {
  margin: 0;
}

.iconmenus.active {
  color: #0069d9;
}

.iconmenus img {
  margin-right: 10px;
}

.nav.question-series .nav-link {
  background: transparent;
  margin: 4px;
  padding: 4px 12px;
  color: #7a7272;
  border-radius: 2px;
  border: 1px solid #7a7272;
  outline: none;
}

.nav.question-series .nav-link.active {
  color: #ffffff;
  text-align: center;
  background: #6d7278;
  position: relative;
}

.nav.question-series .nav-link.active::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 10px solid #6a6a6a;
  position: absolute;
  bottom: -20px;
  left: 35%;
}

.interview-footer .form-group {
  margin-bottom: 2rem;
}

.profile_username {
  margin-left: 30px;
  border-radius: 50%;
}

.save-change-warning-icon {
  color: #ff0000;
  font-size: 24px;
  margin-right: 10px;
}

.clearfix {
  float: none;
  clear: both;
  width: 100%;
  display: inline-block;
}

.date-picker-container:hover {
  background: #fff;
}

.date-picker-container:hover .react-datepicker-wrapper [class$="input-container"] {
  z-index: 2;
}

.date-picker-container:hover .react-datepicker-wrapper input {
  background: transparent;
}

.date-picker-container:hover .calendar_icon {
  z-index: 9;
}

.avoid-clicks {
  pointer-events: none;
}

.zi-4 {
  z-index: 4 !important;
}

.participant.select-all-dropdown {
  background-color: transparent;
  border-color: transparent;
  padding: 0;
  color: #757575;
  margin-right: 5px;
}

.participant.select-all-dropdown:hover {
  color: #757575;
  background-color: transparent;
  border-color: transparent;
}

/* .participant.select-all-dropdown:not(:disabled):not(.disabled).active, 
.participant.select-all-dropdown:not(:disabled):not(.disabled):active, 
.show>.participant.select-all-dropdown.dropdown-toggle{
  color: #757575;
  background-color: transparent;
  border-color: transparent;
}
.participant.select-all-dropdown:not(:disabled):not(.disabled).active:focus, 
.participant.select-all-dropdown:not(:disabled):not(.disabled):active:focus, 
.show>.participant.select-all-dropdown:focus{
  box-shadow: none;
} */
.participant.select-all-dropdown .btn-primary:not(:disabled):not(.disabled).active,
.participant.select-all-dropdown .btn-primary:not(:disabled):not(.disabled):active,
.participant.select-all-dropdown .show>.btn-primary.dropdown-toggle {
  color: #757575;
  background-color: transparent;
  border-color: transparent;
}

.participant.select-all-dropdown .btn-primary:not(:disabled):not(.disabled).active:focus,
.participant.select-all-dropdown .btn-primary:not(:disabled):not(.disabled):active:focus,
.participant.select-all-dropdown .show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.participant.select-all-dropdown .btn-primary:not(:disabled):not(.disabled).active:focus,
.participant.select-all-dropdown .btn-primary:not(:disabled):not(.disabled):active:focus,
.participant.select-all-dropdown .show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.participant.select-all-dropdown .dropdown-item {
  color: #3e3f42;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}

.participant.select-all-dropdown .dropdown-item:active {
  background-color: #eaedf3;
}

.participant.select-all-dropdown .dropdown-menu {
  border-radius: 3px;
  border-color: #eaedf3;
  background: #ffffff;
  box-shadow: 0px 6px 16px -4px rgba(0, 0, 0, 0.45);
  height: 110px;
}

.participant.select-all-dropdown .custom-btn {
  background: transparent;
  border-color: transparent;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.participant.select-all-dropdown .icon-down {
  color: #757575;
}

.data-menu {
  display: none;
  position: absolute;
  top: -7px;
  left: -12px;
  z-index: 6;
  width: 208px;
  min-height: 213px;
  height: auto;
  border-radius: 4px;
  border: solid 1px #979797;
  background: #ffffff;
  box-shadow: 0px 10px 10px 4px rgba(100, 100, 100, 0.5);
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.data-option-container {
  font-size: 14px;
  line-height: 30px;
}

.data-option-container:hover .data-menu {
  display: block;
}

.data-option-container .data-item {
  display: block;
  padding: 6px 11px;
}

.data-option-container .data-item span,
.data-option-container .data-control span {
  line-height: 18px;
}

.participants-page .add-participant-link {
  text-decoration: none;
}

.participants-page .add-participant-link span {
  color: #3e3f42;
  padding-left: 15px;
  /* font-size: 14px; */
}

.footer {
  position: relative;
  bottom: 0;
  width: 100%;
  left: 0;
}

.copyright {
  font-size: 0.85rem;
  color: #6a6a6a;
}

.badge-lg {
  padding: 0.7em 0.8em;
  font-size: 90%;
}

.account-labels {
  float: right;
  margin-top: -60px;
}

.help_icon {
  font-size: 2rem;
  color: #0043ff;
}

.help_link {
  margin-right: 1rem;
  padding: 0.5rem 0.5rem;
}

.react-datepicker-popper {
  z-index: 99;
}

.react-csv-input {
  display: inline-block;
}

.react-csv-input .csv-input {
  width: 100%;
  /* visibility: hidden; */
  position: absolute;
  /* padding: 10px 0px; */
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  z-index: 9;
  height: 35px;
}

.edit-desc-box {
  min-height: 35px;
}

.edit-textarea.hover {
  height: 100px;
  position: absolute;
  bottom: 0px;
  z-index: 9;
  padding-top: 10px;
  padding-bottom: 10px;
}

.form-control.edit-textarea[readonly] {
  background: #fff;
}

.icon-box {
  position: absolute;
  right: 0;
  bottom: -24px;
  font-size: 16px;
}

.about-card {
  border: none;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
  border-radius: 0px;
}

.about-logo {
  width: auto;
  height: 50px;
}

.about-content-box {
  width: 30%;
  margin: auto;
}

@media (max-width: 767px) {
  .about-content-box {
    width: 56%;
  }
}

@media (max-width: 600px) {
  .sidebar-wrapper a.active {
    width: 50px;
  }

  button.btn-rounded.btn-sm.buttontoggle.btn.btn-primary,
  .sidebar.open .sidebar-text {
    display: none;
  }

  .sidebar.open {
    width: 50px;
  }

  .multicolor-bar .legends .legend .label {
    margin-left: 0px;
    font-size: 10px;
  }

  .multicolor-bar .values .value {
    font-size: 12px;
  }

  .sidebar.open+.main-panel {
    width: calc(100% - 50px);
    margin-left: 50px;
  }

  .about-content-box {
    width: 98%;
  }
}

.react-csv-input1 input {
  color: transparent;
  width: 0;
  outline: none;
}

.react-csv-input1 label {
  position: relative;
  z-index: 99;
  cursor: pointer;
  font-size: 0.9rem;
  padding-left: 10px;
}

.react-csv-input1 input::-webkit-file-upload-button {
  visibility: hidden;
}

.piihover:hover {
  color: #0069d9;
}

.react-csv-input1 input::before {
  content: "";
  color: black;
  display: inline-block;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
  position: absolute;
  height: 35px;
}

.react-csv-input1 input:hover::before {
  border-color: black;
}

.react-csv-input1 input:active {
  outline: 0;
}

.react-csv-input1 input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.uploadpiiicon {
  position: absolute;
  left: 10px;
  top: 2px;
}

/* Multicolor progress bar Css */
/* .multicolor-bar {
	margin: 20px 20%;
} */

.multicolor-bar .values .value {
  float: left;
  text-align: center;
  font-size: 14px;
}

.multicolor-bar .scale .graduation {
  float: left;
  text-align: center;
}

.multicolor-bar .bars .bar {
  float: left;
  height: 10px;
}

.multicolor-bar .bars div.bar:first-of-type {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.multicolor-bar .bars div.bar:last-of-type {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.multicolor-bar .legends {
  text-align: center;
}

.multicolor-bar .legends .legend {
  display: inline-block;
  margin: 2px 10px;
  text-align: center;
}

.multicolor-bar .legends .legend .dot {
  font-size: 16px;
  vertical-align: middle;
}

.multicolor-bar .legends .legend .label {
  margin-left: 2px;
  vertical-align: middle;
  font-size: 13px;
}

.table_wrapper.sms_tracking_table tr th {
  padding-top: 12px;
  width: 200px;
}

.main-loader {
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100vh;
  font-size: 16px;
}

.something-went-wrong {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f8f8f8;
}

.something-went-wrong h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.something-went-wrong p {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

.freshWork-container {
  margin: 0px 30px;
}

.modal-backdrop {
  z-index: 9999;
}

.modal {
  z-index: 9999;
}

.as_result_page_table .react-bootstrap-table {
  overflow: inherit;
  display: block;
  table-layout: auto;
  white-space: nowrap;
  max-height: inherit;
  overflow-y: inherit;
}

iframe {
  width: 100%;
}

.tables-control-menu .nav-item div span {
  margin-right: 8px;
}

.freshWork-container a {
  color: #fff;
  background: #1665d8;
  border-radius: 4px;
  padding: 13px 15px;
  font-size: 17px;
}

.report_result_table td:nth-child(2),
.report_result_table td:nth-child(3),
.report_result_table td:nth-child(5) {
  word-wrap: break-word;
  white-space: break-spaces;
}

.content-wrapper {
  height: 100vh;
}

body .MuiGrid2-item,
body .MuiCardContent-root {
  position: relative;
}

body .MuiPaper-root.MuiCard-root {
  margin-bottom: 25px;
  overflow: visible;
}

body .MuiPaper-root.MuiCard-root.more-space {
  margin-bottom: 35px;
}

body .MuiCardHeader-root {
  padding: 3px 16px;
  border-bottom: 1px solid #eaedf3;
}

body .card-header-no-border.MuiCardHeader-root {
  border-bottom: none;
}

body .more-space.MuiCardHeader-root {
  padding: 12px 24px;
}

body .MuiCardHeader-title {
  font-size: 16px;
  font-weight: 600;
}

/* body .MuiGrid2-root.MuiGrid2-container {
  margin-left: -15px;
  margin-right: -15px;
  width: calc(100% + 30px);
} */

.sidebar-wrapper .flex-column.nav a {
  display: flex;
  flex-direction: row;
}

.sidebar-wrapper .flex-column.nav a svg {
  margin-right: 10px;
}

.display-inline {
  display: inline-block;
}

.vertical-align-middle {
  vertical-align: middle;
}

.table td,
.table th {
  padding: 10px 12px;
}