body {
  background-color: #fbfbfd;
  /* font-family: 'Poppins', sans-serif; */
}

a {
  color: #1665d8;
}

.DraftEditor-editorContainer {
  padding: 10px;
}

.alert p {
  margin-bottom: 0;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.container-fluid {
  position: relative;
}

.f-15 {
  font-size: 15px !important;
}

.error-card {
  box-shadow: 2px 6px 15px -7px rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 5px;
  background-color: #fdd9d9;
  text-align: center;
  padding: 0px;
  max-width: 370px;
  overflow: hidden;
}

.error_desc_inner h3 {
  font-weight: bold;
  font-size: 30px;
}

.error_icon svg {
  font-size: 60px;
  color: #e88b8b;
}

.btn.backtologin {
  border-radius: 6px;
  color: #ffffff !important;
}

.error_icon {
  padding: 60px 15px 90px;
}

.error_desc {
  background: #fff;
  position: relative;
  padding: 30px 0px 20px;
  z-index: 9;
}

.error_desc:before {
  content: "";
  position: absolute;
  top: -30px;
  right: 0;
  height: 400px;
  background: #ffffff;
  border-radius: 50%;
  width: 200%;
  left: -50%;
}

.error_desc_inner {
  position: relative;
}

input.error {
  border-color: red;
}

.input-feedback {
  color: red;
  margin-top: 0.25rem;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f2f2f2;
  opacity: 1;
  border-color: #f2f2f2;
  color: #999999;
  cursor: not-allowed;
}

.select__control.select__control--is-disabled .select__placeholder {
  color: #999;
}

.date-picker-container:hover .react-datepicker-wrapper input:disabled {
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}

.input-group-append.disabled span {
  background-color: #f2f2f2;
  border: 1px solid transparent;
  border-left: 1px solid #eae3e3;
  margin-left: 1px;
  color: #999;
}

.input-group-append.disabled {}

.form-control {
  /* border-color: #E9EBF1; */
  border: solid 1px #e4e8ee;
  box-shadow: 0px 1px2px rgba(0, 0, 0, 0.08);
  font-size: 14px;
  height: 40px;
}

.w-1200 {
  /* max-width: 1200px; */
  max-width: inherit;
  position: relative;
}

.mr-75 {
  margin-right: 0.75rem;
}

.select-style {
  font-size: 14px;
  min-height: 38px;
  box-shadow: 0px 1px2px rgba(0, 0, 0, 0.08);
}

.select-style [class$="-menu"] {
  z-index: 10 !important;
}

.select-style.callcenter-select {
  height: auto;
  min-height: 38px;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: none;
  outline: 0;
  box-shadow: none;
}

form label {
  color: #9ea0a5;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 0px;
}

.interview label {
  color: #9ea0a5;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 20px;
}

form {
  /* padding: 0px 30px; */
  padding: 0px 0px;
}

textarea.form-control {
  color: #9ea0a5;
}

textarea::-webkit-input-placeholder {
  color: #9ea0a5;
}

textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #9ea0a5;
}

textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #9ea0a5;
}

textarea:-ms-input-placeholder {
  color: #9ea0a5;
}

.input-feedback {
  font-size: 14px;
}

::placeholder {
  /* Firefox, Chrome, Opera */
  color: #9ea0a5;
  font-size: 14px;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #9ea0a5;
  font-size: 14px;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #9ea0a5;
  font-size: 14px;
}

.main-wrapper {
  width: 30%;
  margin: auto;
  margin-top: 2%;
  min-height: 389px;
}

.main-wrapper .logo-img {
  margin-bottom: 20px;
  text-align: center;
}

.main-wrapper .logo-img img {
  width: 300px;
  height: 50px;
  object-fit: contain;
}

.wrapper-content {
  border: none;
  box-shadow: 2px 6px 15px -7px #d5d4d7;
  border-radius: 6px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal-content {
  box-shadow: 2px 6px 15px -7px rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 5px;
}

.wrapper-content .left,
.wrapper-content .right {
  float: left;
}

.wrapper-content .left {
  background: #fff;
  width: 100%;
  padding: 50px 30px;
}

.wrapper-content .right {
  /*  background-image: url('../../../assets/images/Pic.png'); */
  object-fit: fill;
  background-size: cover;
  background-position: center;
  height: auto;
  width: 54%;
}

.wrapper-content .upper-check-box {
  position: absolute;
  width: 44px;
  height: 44px;
  border-radius: 0.25rem;
  background: #1867db;
  left: 30px;
  top: -22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom-link {
  color: #9ea0a5;
}

.btn {
  border-radius: 0px;
  font-size: 0.9rem;
  /* padding: 0.7rem 3.5rem; */
  padding: 0.7rem 2rem;
}

.btn-std {
  padding: 0.7rem 1.5rem;
  border-radius: 6px;
}

.react-csv-input {
  display: inline-block;
  margin-left: 0.5rem !important;
}

.react-csv-input label {
  margin-bottom: 0;
}

.resultAction-dropdown .dropdown-item {
  position: relative;
  color: #0069d9;
  cursor: pointer;
  z-index: 1;
}

/* .btn-primary:hover {
  background: #34AA44;
  color: #fff;
  border-color: #34AA44;
}
.btn-primary.disabled, .btn-primary:disabled {
  background: #34AA44;
  color: #fff;
  border-color: #34AA44;
}
.btn-primary:not(:disabled):not(.disabled).active, 
.btn-primary:not(:disabled):not(.disabled):active, 
.show>.btn-primary.dropdown-toggle {
  background: #34AA44;
  color: #fff;
  border-color: #34AA44;
}
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
} */
.btn-secondary {
  background: #8c9ba5;
  border-color: #8c9ba5;
}

.btn-primary {
  background-color: #1665d8;
  border-color: #1461d2;
}

.btn-success {
  background-color: #34aa44;
  border-color: #34aa44;
}

.btn-rounded {
  border-radius: 50% !important;
  /* padding: .375rem .75rem; */
}

.btn-sm {
  font-size: 1.1rem;
  padding: 0.3rem 0.44rem;
  line-height: 1.1;
}

.btn-sm-text {
  font-size: 0.9rem;
  padding: 5px;
  line-height: 19px;
}

.btn-outline {
  background: transparent;
}

.btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.btn-primary.focus,
.btn-primary:focus {
  box-shadow: none;
}

.form-title {
  color: #3e3f42;
  font-size: 22px;
  margin-bottom: 20px;
  font-weight: bold;
}

/* project details */
.tags {
  display: inline-block;
  background-color: #fdeacb;
  color: #000;
  width: auto;
  padding: 5px 10px;
  margin-right: 5px;
  font-size: 14px;
}

.btn-primary:not(:disabled):not(.disabled).hover {
  background: #1455b6;
  color: #fff;
  border-color: #1455b6;
}

.send-invite.btn-primary:not(:disabled):not(.disabled).active,
.send-invite.btn-primary:not(:disabled):not(.disabled):active,
.show>.send-invite.btn-primary.dropdown-toggle {
  background: #1455b6;
  color: #fff;
  border-color: #1455b6;
}

.send-invite {
  background: #1455b6;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.status-circle {
  width: 100px;
}

.line {
  width: 2px;
  height: 30px;
  border: 1px solid #cccccc80;
}

.circle {
  width: 24px;
  height: 24px;

  border-radius: 24px;
  border: 6px solid #c4cdd5;
}

.circle.blue {
  background: #0091ff;
}

.circle.grey {
  background: #aeb0b3;
}

/* 
.save-btn, .cancel-btn {
 width: 120px;
  font-size: 14px; 
}*/
.inter-text {
  color: #9ea0a5;
  font-size: 0.88rem;
  text-transform: uppercase;
  font-weight: 500;
  margin-top: 10px;
}

.calendar_icon {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #9ea0a5;
  opacity: 0.7;
}

.react-datepicker-wrapper {
  width: 100%;
}

.label-withline {
  position: relative;
  border: 1px solid #ddd;
  padding: 15px;
  margin-top: 20px;
  border-radius: 5px;
}

.label-withline_text {
  background: #fff;
  position: absolute;
  top: -19px;
  text-transform: uppercase;
  padding: 10px 15px 0 15px;
  font-size: 17px;
}

.label-withline h6 {
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 15px;
}

.form-group.email-notify>div {
  color: #9ea0a5;
}

.email-notify .form-check {
  padding-left: 0;
}

.inheritHeight .react-bootstrap-table {
  height: auto;
  max-height: inherit;
}

.overall-sort {
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.overall-sort span {
  margin-left: 7px;
}

.overall-sort img {
  margin-bottom: 5px;
}

.react-bootstrap-table table th {
  background: #f7f7f7;
  color: #333;
  text-transform: uppercase;
  font-size: 0.8rem;
}

.email-wrapper {
  border: 1px solid #e4e8ee;
  margin: 0 15px;
}

.helper-box {
  min-height: 150px;
  padding: 15px;
  font-size: 14px;
  width: 500px;
}

.helper-box-content {
  text-transform: uppercase;
}

.email-temaplate-row .form-control {
  height: auto;
}

.email-temaplate-row .basic-multi-select,
.email-temaplate-row .basic-multi-select>div {
  font-size: 14px;
}

.email-temaplate-row .col-form-label {
  text-transform: capitalize;
  font-weight: normal;
}

.email-wrapper .rdw-editor-main {
  height: 300px;
}

.htmltemplate-view {
  min-height: 150px;
}

.email-temaplate-row .basic-multi-select {
  margin-bottom: 0;
}

.template-preview {
  background: #f9f9fb;
  position: relative;
  padding: 20px 50px;
  margin-bottom: 30px;
}

.template-preview>h4 {
  text-align: center;
}

.template-section {
  background: #fff;
  padding: 15px;
  white-space: pre-wrap;
}

.template-section P {
  margin-bottom: 15px;
  display: block;
  min-height: 5px;
}

.template-section-sms {
  background: #fff;
  min-height: 200px;
  padding: 15px;
  white-space: pre-wrap;
}

.exceeded-textarea-text {
  background: #e9bdbd;
  padding: 8px;
  border-radius: 5px;
}

.template-section-sms P {
  margin-bottom: 15px;
  display: block;
  min-height: 5px;
}

.email-wrapper .public-DraftStyleDefault-block {
  margin: 0;
}

.access-title {
  float: left;
  margin-top: 10px;
}

.right-iconmenus-overflow {
  overflow: hidden;
}

p.MuiTypography-root.typography-selected-count {
  color: #155724;
}

.nav-tabs {
  justify-content: space-between;
  align-items: center;
}

.nav-tabs .nav-link {
  width: 100%;
}

body .app-table {
  min-height: 250px;
}

body .app-table .MuiTableContainer-root.paper-table-container {
  min-height: 250px;
}

body .participant-module-table {
  height: calc(100vh - 434px);
}

body .result-module-table .MuiTableContainer-root.paper-table-container {
  height: calc(100vh - 542px);
  overflow-y: auto;
}

body .document-module-table {
  height: calc(100vh - 318px);
}

body .backenddata-module-table {
  height: calc(100vh - 374px);
}

body .incompletecalls-module-table,
body .report-users-module-table {
  height: calc(100vh - 496px);
}

body .report-result-module-table {
  height: calc(100vh - 618px);
}

body .admin-account-module-table,
body .admin-user-module-table,
body .admin-role-module-table,
body .admin-lookup-module-table,
body .admin-callcenter-module-table,
body .admin-application-module-table,
body .admin-orderdetail-module-table,
body .admin-resultconfiguration-module-table {
  height: calc(100vh - 374px);
}

body .analytic-reports-module-table {
  height: calc(100vh - 270px);
}

body .accesscode-module-table {
  height: calc(100vh - 374px);
}

body .view-accesscode-module-table {
  height: calc(100vh - 495px);
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .main-wrapper {
    width: 95%;
  }

  .wrapper-content .left,
  .wrapper-content .right {
    width: 50%;
  }
}

@media (max-width: 580px) {
  .main-wrapper {
    width: 100%;
  }

  .wrapper-content .left {
    width: 100%;
  }

  .wrapper-content .right {
    display: none;
  }

  .form-title {
    font-size: 20px;
    margin-bottom: 20px;
  }
}

.page-title {
  color: #3e3f42;
  /* border-bottom: 1px solid #EAEDF3; */
  /* padding: 1rem 0rem; */
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}

.page-breadcrumb {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #3e3f42;
}

.page-breadcrumb i {
  font-size: 12px;
  font-weight: 600;
}

.page-breadcrumb a,
.page-breadcrumb span {
  color: #1665d8;
}

.project-list.page-breadcrumb>svg {
  font-size: 14px;
}

.internal_view {
  margin: 0px 0px 20px 0px;
  border: 2px solid #1455b6;
  border-radius: 2px;
}

.perpage {
  width: 110px;
}

/* .react-bootstrap-table th .caret-4-desc::after {
  color: #1455b6;
}

.react-bootstrap-table th .caret-4-asc::before {
  color: #1455b6;
} */


.image-container {
  width: 36px;
  height: 36px;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center, center;
  vertical-align: middle;
  margin-left: 10px;
}

.team-image-container {
  width: 3.75em;
  height: 3.75em;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center, center;
  vertical-align: middle;
  margin-left: 30px;
  border-radius: 50%;
}

.card-code {
  padding: 10px;
  background-color: aliceblue;
}

.card-code pre {
  white-space: normal;
  color: #333;
}

.form-control.dial-in-readonly:disabled,
.form-control[readonly].dial-in-readonly {
  border: none;
  border-radius: inherit;
  background: transparent;
  border-bottom: 1.5px solid #cccccc;
}

.table_wrapper tr th,
.table_wrapper_modal tr th {
  background: #f7f7f7;
  color: #333;
  text-transform: uppercase;
}

.page-link {
  cursor: pointer;
}

.hoverbox {
  position: relative;
  display: inline-block;
}

.hoverbox:hover .showhoverbox {
  display: block;
}

.showhoverbox {
  display: none;
  position: absolute;
  background: #000;
  box-shadow: 2px 6px 15px -7px rgba(0, 0, 0, 0.2);
  top: -33px;
  color: #fff;
  right: -12px;
  padding: 5px;
  font-size: 12px;
  width: max-content;
  z-index: 999;
  text-align: center;
}

.mark_reverse_np {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 4px;
  background-color: #eeeeee;
  border: 1px solid #ff5555;
  cursor: pointer;
}

.mark_np {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 4px;
  background-color: #eeeeee;
  border: 1px solid #338c1c;
  cursor: pointer;
}

.showhoverbox:after,
.showhoverbox:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.showhoverbox:after {
  border-color: rgba(136, 183, 213, 0);
  border-top-color: #000;
  border-width: 5px;
  margin-left: -5px;
}

.showhoverbox.showOnleft:after,
.showhoverbox.showOnleft:before {
  top: 36%;
  left: auto;
  transform: rotate(270deg);
  right: -10px;
}

.showhoverbox.showOnleft {
  top: 0px;
  right: 40px;
}

.graph-card .card-body {
  height: 320px;
  position: relative;
}

.graph-card canvas {
  height: 100% !important;
  width: auto !important;
}

.email-notify .form-check-label {
  text-transform: capitalize;
}

.table-div {
  display: table;
  border-collapse: collapse;
}

.th-div {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.table-div .th-div .td-div {
  padding-top: 30px;
}

.th-div .td-div {
  text-transform: uppercase;
  font-size: 0.8rem;
  background: #f7f7f7;
  color: #333;
  font-weight: 400;
  display: table-cell;
  padding: 0.75rem;
}

.tr-div .td-div {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  color: #757575;
}

.tr-div {
  display: table-row;
}

.td-div {
  display: table-cell;
}

.serial-no {
  background-color: #6fbaec;
  width: 30px;
  height: 30px;
  line-height: 30px;
  color: #fff;
  text-align: center;
  display: inline-block;
  border-radius: 50px;
}

ul.sub-tab.nav {
  background: #fff;
  margin-bottom: 15px;
  box-shadow: 2px 6px 15px -7px rgba(0, 0, 0, 0.2);
}

ul.sub-tab.nav a {
  color: #6d7278;
  padding: 15px 30px;
  text-decoration: none;
  border-left: 8px solid transparent;
  min-width: 150px;
  text-align: center;
  font-weight: bold;
}

ul.sub-tab.nav a.active {
  background-color: #f6f9fd;
  border-left: 8px solid #1665d8;
  color: #000;
}

.clikablelabel {
  color: #1665d8;
  cursor: pointer;
}

.space-wrap {
  white-space: pre-line;
}

p.inter-text.inner-heading {
  color: #58a7f8;
}

.search-custom {
  position: relative;
}

.searchicon {
  position: absolute;
  color: #adb5bd;
  right: 12px;
  top: 12px;
}

.search-wrapper .search-label {
  display: block !important;
}

.react-bootstrap-table-pagination button#pageDropDown {
  background: #37a0f7 !important;
  border-color: #37a0f7 !important;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.react-bootstrap-table-pagination .dropdown-toggle {
  padding: 5px 10px;
}

.react-bootstrap-table-pagination ul.dropdown-menu {
  top: auto !important;
  bottom: 27px;
  transform: none !important;
}

.react-bs-table-sizePerPage-dropdown.dropdown .dropdown-menu.open.show {
  max-width: 56px;
  min-width: 56px;
}

.dropdown-menu {
  box-shadow: 2px 3px 15px 2px rgb(31 30 30 / 20%);
  border: none;
}

.react-bs-table-sizePerPage-dropdown li.dropdown-item {
  padding: 0;
}

.react-bs-table-sizePerPage-dropdown li.dropdown-item a {
  display: block;
  padding: 0.25rem 1rem;
}

.dropdown-toggle:after {
  /* display: inline-block; */
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3emsolid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.row.react-bootstrap-table-pagination {
  flex-direction: row-reverse;
  margin-top: 20px;
}

.tenant-table .react-bootstrap-table .table {
  margin-bottom: 0;
}

.search-wrapper {
  position: absolute;
  top: 17px;
  right: 75px;
  width: 40%;
}

.row.react-bootstrap-table-pagination>.col-md-6 {
  text-align: right;
}

.react-bootstrap-table th .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.react-bootstrap-table th .dropdown,
.react-bootstrap-table th .dropup {
  position: relative;
}

.react-bootstrap-table th .dropup .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid\9;
}

.react-bootstrap-table th .order>.dropdown>.caret {
  margin: 10px 0 10px 5px;
  color: #cccccc;
}

.react-bootstrap-table th .order>.dropup>.caret {
  margin: 10px 0;
  color: #cccccc;
}

.tenant-table .react-bootstrap-table {
  overflow-y: auto;
  max-height: calc(100vh - 335px);
  position: relative;
  min-height: calc(100vh - 335px);
}

button.additional_configuration_link {
  background: transparent !important;
  border: none !important;
  color: #1665d8;
  text-decoration: underline;
  font-weight: 400;
  font-size: 15px;
  margin-top: 32px;
}

input.configuration-file-input[type="file" i]:disabled:before {
  cursor: not-allowed;
  border: none;
  content: "Uploading..";
  background: #a3c1e1;
}

.filter_project_row>div {
  margin-right: 15px;
}

.filter_project_row>div:last-child {
  margin-right: 0;
}

.filter_project_row button {
  min-width: 200px;
  box-shadow: none;
  border: 1px solid #cccccc;
  outline: none !important;
  border-radius: 4px;
  min-height: 38px;
  font-size: 14px;
}

.filter_project_row .select-style {
  min-width: 200px;
}

.filter_project_row .select-style>div {
  box-shadow: none;
}

.projectlist {
  position: relative;
  z-index: 1;
}

.filter_project_row>div>div {
  /* box-shadow: 2px 6px 15px -7px rgba(0, 0, 0, 0.2); */
  width: 100%;
}

.filter_project_row.filter__users__dropdown {
  z-index: 99;
}

.filter_project_row.filter__users__dropdown button {
  min-width: 100%;
}

body .reactmultiselectcheckchildren__control {
  min-width: 1px;
  width: 100%;
  max-width: 100%;
  margin: 0;
}

.reactmultiselectcheck {
  padding: 8px;
}

body .reactmultiselectcheckchildren__menu {
  box-shadow: none;
  margin-left: -8px;
  margin-right: -8px;
}

body .reactmultiselectcheckchildren__option {
  min-width: 1px;
}

.project_filter_label label {
  margin-bottom: 0;
}

.additional_configuration_accordion .accordion__button {
  color: #1665d8;
  cursor: pointer;
  background: transparent;
  padding: 10px;
}

.additional_configuration_accordion .accordion__button label.row-header-title {
  color: #1665d8;
  text-decoration: none;
  font-weight: 400;
  font-size: 15px;
  cursor: pointer;
}

.additional_configuration_accordion .accordion__panel {
  border: 2px solid #1455b6;
  border-radius: 2px;
}

.dashboard_tile_card .custom-loader {
  min-height: 87px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.dashboard_tile_graph .custom-loader {
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 255px;
}

.tenant-table .react-bootstrap-table .table,
.tenant-manage-table {
  background: #fff;
}

.theme_change {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  transform: none;
}

.theme_change lable {
  margin-right: 10px;
}

.theme_change .theme_switch {
  display: inline-block;
  position: relative;
}

.theme_change .switch__input {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}

.theme_change .switch__label {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  transform: none;
  background-color: #2b2b2b;
  border: 1px solid #5b5b5b;
  border-radius: 9999px;
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.46, 0.03, 0.52, 0.96);
  margin: 0 0 0 20px;
  vertical-align: middle;
}

.theme_change .switch__indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateX(-72%);
  display: block;
  width: 15px;
  height: 15px;
  background-color: #7b7b7b;
  border-radius: 9999px;
  box-shadow: 10px 0px 0 0 rgba(0, 0, 0, 0.2) inset;
}

.theme_change .switch__indicator::before,
.switch__indicator::after {
  position: absolute;
  content: "";
  display: block;
  background-color: #ffffff;
  border-radius: 9999px;
}

.theme_change .switch__indicator::before {
  top: 10px;
  left: 7px;
  width: 6px;
  height: 6px;
  background-color: #ffffff;
  opacity: 0.6;
}

.theme_change .switch__indicator::after {
  bottom: 7px;
  right: 8px;
  width: 8px;
  height: 8px;
  background-color: #ffffff;
  opacity: 0.8;
}

.theme_change .switch__decoration {
  position: absolute;
  top: 65%;
  left: 50%;
  display: block;
  width: 5px;
  height: 5px;
  background-color: #ffffff;
  border-radius: 9999px;
  animation: twinkle 0.8s infinite -0.6s;
  /* display: none; */
}

.theme_change .switch__decoration::before,
.switch__decoration::after {
  position: absolute;
  display: block;
  content: "";
  width: 5px;
  height: 5px;
  background-color: #ffffff;
  border-radius: 9999px;
}

.theme_change .switch__decoration::before {
  top: -12px;
  left: 2px;
  opacity: 1;
  animation: twinkle 0.6s infinite;
}

.theme_change .switch__decoration::after {
  top: -7px;
  left: 15px;
  animation: twinkle 0.6s infinite -0.2s;
}

@keyframes twinkle {
  50% {
    opacity: 0.2;
  }
}

.theme_change .switch__indicator,
.switch__indicator::before,
.switch__indicator::after {
  transition: all 0.4s cubic-bezier(0.46, 0.03, 0.52, 0.96);
}

.theme_change .switch__input:checked+.switch__label {
  background-color: #8fb5f5;
  border-color: #347cf8;
}

.theme_change .switch__input:checked+.switch__label .switch__indicator {
  background-color: #ecd21f;
  box-shadow: none;
  transform: translate(-50%, -50%) translateX(72%);
}

.theme_change .switch__input:checked+.switch__label .switch__indicator::before,
.theme_change .switch__input:checked+.switch__label .switch__indicator::after {
  display: none;
}

.theme_change .switch__input:checked+.switch__label .switch__decoration {
  top: 50%;
  transform: translate(0%, -50%);
  animation: cloud 8s linear infinite;
  width: 13px;
  height: 10px;
}

.theme_change .switch__input:checked+.switch__label .switch__decoration::before {
  width: 4px;
  height: 4px;
  top: auto;
  bottom: 0;
  left: -3px;
  animation: none;
}

.theme_change .switch__input:checked+.switch__label .switch__decoration::after {
  width: 6px;
  height: 6px;
  top: auto;
  bottom: 0;
  left: 12px;
  animation: none;
}

.theme_change .switch__input:checked+.switch__label .switch__decoration,
.theme_change .switch__input:checked+.switch__label .switch__decoration::before,
.theme_change .switch__input:checked+.switch__label .switch__decoration::after {
  border-radius: 9999px 9999px 0 0;
}

.theme_change .switch__input:checked+.switch__label .switch__decoration::after {
  border-bottom-right-radius: 9999px;
}

@keyframes cloud {
  0% {
    transform: translate(0%, -50%);
  }

  50% {
    transform: translate(-50%, -50%);
  }

  100% {
    transform: translate(0%, -50%);
  }
}

.table_wrapper .document_file_name {
  color: rgb(22, 101, 216);
  cursor: pointer;
}

.dashboard_tile_graph .custom-loader {
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 255px;
}

.file_upload_loader_full_screen {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background: rgba(255, 255, 255, 0.9);
  z-index: 9999;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file_upload_loader_full_screen .custom-loader .spinner-border {
  /* border: 0.25em solid #fff;
  border-right-color: transparent; */
}

.file_upload_loader_full_screen p {
  color: #000000;
  font-weight: bold;
  margin-left: 15px;
  margin-bottom: 0;
  font-size: 18px;
}

.key_value_repeater {
  margin-top: 0px;
}

button.btn-secondary.remove_repeater {
  border-radius: 15px;
  margin-top: 8px;
  width: 30px;
  height: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

button.btn-secondary.remove_repeater .trashicon {
  font-size: 14px;
}

.pa-0 {
  padding: 0;
}

button.add_additional_fields_btn {
  margin-bottom: 0rem;
  padding: 7px 10px;
}

.delete_icon_key_value_repeater {
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accordion__panel textarea.form-control {
  color: #495057;
}

.filter_sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 69px;
  z-index: 9;
}

td.column_text_center {
  text-align: center;
}

.user-locale {
  margin-right: 15px;
}

.blue-text {
  color: #1665d8;
}

.theme_appearance {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.internal_server_error_response {
  height: 100vh;
  flex-direction: column;
}

.internal_server_error_response p {
  margin: 0 auto;
  font-size: 16px;
  color: #504e4e;
  text-align: center;
  padding: 15px;
}

.error-red {
  color: #e88b8b;
}

.backto-wrapper {
  border-top: 1px solid #ddd;
  margin-top: 10px;
  display: block;
}

.file_download_link_parent p {
  color: #1665d8;
  cursor: pointer;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 18px;
  margin-top: 10px;
}

body .react-tel-input .form-control {
  border: solid 1px #e4e8ee;
  height: 40px;
  width: 100%;
  padding: 0.375rem 0.75rem 0.375rem 48px;
  border-radius: 0.25rem;
}

.tooltip {
  border: none;
  background: transparent;
  font-size: 14px;
  max-width: 400px;
}

.tooltip-inner {
  max-width: 100%;
  background-color: #2a2929;
}

.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #2a2929;
}

.legend_tooltip .__react_component_tooltip {
  font-size: 14px;
}

.legend_tooltip .__react_component_tooltip.type-dark {
  max-width: inherit !important;
}

span[class^="filter_risk"] {
  color: #fff;
  font-size: 10px;
  padding: 4px 1px 1px;
  min-width: 30px;
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
  border-radius: 2px;
  text-align: center;
}

.tab-content span[class^="filter_risk"] {
  min-width: 28px;
}

span.filter_risk_HR {
  background: #ff0000;
}

span.filter_risk_R {
  background: #ff0000;
}

span.filter_risk_G {
  background: #92d050;
}

span.filter_risk_LR {
  background: #008000;
}

span.filter_risk_AR {
  background: #92d050;
}

span.filter_risk_PR {
  background: #ffca00;
}

span.filter_risk_NC {
  background: #bcbcbc;
}

span.filter_risk_DC {
  background: #6c6b6b;
}

span.filter_risk_CM {
  background: #6758f3;
}

span.filter_risk_AD {
  background: #1665d8;
}

span.filter_risk_PD {
  background: #f2933d;
}

span.filter_risk_NP {
  background: #ffffff;
  border: 1px solid #000000;
  color: #000000;
}

.reactmultiselectcheck .reactmultiselectcheckchildren__option {
  font-size: 14px;
}

.filter_project_row.multiselect_filter button {
  width: 100%;
  min-width: auto;
  font-size: 13px;
}

.filter_project_row.multiselect_filter.disable_drop_down button {
  background-color: rgb(242 242 242);
  pointer-events: none;
  border: 1px solid rgb(230 230 230);
  color: #999;
}

.filter_project_row.multiselect_filter>div>div {
  min-width: 300px;
  box-shadow: 2px -4px 27px -7px rgb(0 0 0 / 20%);
  margin-top: 2px !important;
}

.multiselect_filter_participant.filter_project_row.multiselect_filter>div>div {
  left: 0;
  width: auto;
}

.filter_project_row.multiselect_filter>div>div>div>div:first-child {
  display: none;
}

.result_filter_section {
  padding: 26px 0;
}

.result_filter_section_new {
  padding: 2px 0;
  height: 87px;
}

.result_applybtn {
  border-radius: 3px;
  padding: 4px 12px;
  line-height: 17px;
  font-size: 13px;
}

.padding-l35 {
  padding-left: 35px;
}

.filter_project_row.multiselect_filter>div>div>div>div>div {
  max-height: 383px;
}

.filter_project_row.multiselect_filter>div>div>div>div>div div {
  font-weight: normal;
  background-color: #fff;
}

.filter_project_row.multiselect_filter>div>div>div>div>div div:hover {
  background-color: #deebff;
}

span.custom-daterange {
  box-shadow: 0px 1px2px rgba(0, 0, 0, 0.08);
  height: 39px;
  padding: 10px;
  border: 1px solid #cccccc;
  outline: none !important;
  border-radius: 4px;
  display: block;
  font-size: 13px;
}

span.result_resetfilter {
  padding: 0 12px;
  color: #515151;
  cursor: pointer;
  font-size: 14px;
}

.risk1 {
  flex: 0 0 23%;
  padding-left: 0;
}

.risk2 {
  flex: 0 0 25%;
  padding-left: 0;
}

.daterangerisk {
  padding-left: 0;
  flex: 0 0 29%;
}

.applyreset-section {
  flex: 0 0 27%;
  padding-left: 0;
  margin-top: 5px;
}

.no-tenants {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: #ffffff;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-tenants p {
  font-size: 18px;
  margin-bottom: 0;
  color: red;
}

.add-tenant-row .basic-multi-select {
  margin-bottom: 0;
  font-size: 14px;
}

/* .basic-multi-select div:nth-child(3) {
  z-index: 3;
} */

.react-bootstrap-table {
  /* white-space: nowrap;
  overflow-x: auto;
  width: 100%; */
  overflow: auto;
  display: block;
  table-layout: auto;
  white-space: nowrap;
  max-height: 500px;
  overflow-y: auto;
}

.react-bootstrap-table table.horizontal-scroll thead {
  z-index: 9;
  position: relative;
}

.react-bootstrap-table table.horizontal-scroll thead th {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1;
}

table.horizontal-scroll.user_table thead th:last-child,
table.horizontal-scroll.result_table thead th:last-child,
table.horizontal-scroll.result_table thead th:first-child,
table.horizontal-scroll.callrecord_table thead th:first-child,
table.horizontal-scroll.result_table thead th:nth-last-child(2),
table.horizontal-scroll.participant_table thead th:last-child,
table.horizontal-scroll.participant_table thead th:first-child,
table.horizontal-scroll.participant_table thead th:nth-last-child(2) {
  position: sticky;
  position: -webkit-sticky;
  right: 0;
  /* z-index: 2; */
  background: #f7f7f7;
}

table.horizontal-scroll.participant_table tbody tr td:nth-last-child(2),
table.horizontal-scroll.participant_table tbody tr td:first-child,
table.horizontal-scroll.participant_table tbody tr td:nth-child(2),
table.horizontal-scroll.callrecord_table tbody tr td:first-child,
table.horizontal-scroll.callrecord_table tbody tr td:nth-child(2),
table.horizontal-scroll.participant_table tbody tr td:last-child,
table.horizontal-scroll.result_table tbody tr td:nth-last-child(2),
table.horizontal-scroll.result_table tbody tr td:first-child,
table.horizontal-scroll.result_table tbody tr td:nth-child(2),
table.horizontal-scroll.user_table tbody tr td:last-child,
table.horizontal-scroll.result_table tbody tr td:last-child {
  position: sticky;
  position: -webkit-sticky;
  right: 0;
  /* z-index: 2; */
  background: #fff;
}

table.horizontal-scroll.result_table thead th:nth-last-child(2),
table.horizontal-scroll.result_table tbody tr td:nth-last-child(2) {
  right: 50px;
  /* z-index: 2; */
}

table.horizontal-scroll.participant_table thead th:nth-last-child(2),
table.horizontal-scroll.participant_table tbody tr td:nth-last-child(2) {
  right: 40px;
  /* z-index: 2; */
}

table.horizontal-scroll.result_table thead th:first-child {
  left: 0;
  z-index: 2;
}

table.horizontal-scroll.result_table tbody tr td:first-child,
table.horizontal-scroll.participant_table tbody tr td:first-child,
table.horizontal-scroll.callrecord_table tbody tr td:first-child {
  left: 0;
  z-index: inherit;
}

table.horizontal-scroll.participant_table thead th:first-child {
  left: 0;
  z-index: 2;
}

table.horizontal-scroll.callrecord_table thead th:first-child {
  left: 0;
  z-index: 2;
}

table.horizontal-scroll.participant_table thead th:first-child,
table.horizontal-scroll.callrecord_table thead th:first-child {
  text-align: center;
}

table.horizontal-scroll.result_table thead th:nth-child(2) {
  left: 50px;
  z-index: 2;
}

table.horizontal-scroll.result_table tbody tr td:nth-child(2) {
  left: 50px;
  z-index: inherit;
}

table.horizontal-scroll.participant_table thead th:nth-child(2) {
  left: 50px;
  z-index: 2;
}

table.horizontal-scroll.participant_table tbody tr td:nth-child(2),
table.horizontal-scroll.callrecord_table tbody tr td:nth-child(2) {
  left: 50px;
  z-index: inherit;
}

table.horizontal-scroll.callrecord_table thead th:nth-child(2) {
  left: 50px;
  z-index: 2;
}

table.horizontal-scroll.result_table thead th:nth-child(3),
table.horizontal-scroll.result_table tbody tr td:nth-child(3) {
  /* left: 80px;
  z-index: 2; */
}

table.horizontal-scroll.result_table thead th:nth-child(4),
table.horizontal-scroll.result_table tbody tr td:nth-child(4) {
  /* left: 120px;
  z-index: 2; */
}

table.horizontal-scroll.result_table.result_permission thead th:nth-last-child(2),
table.horizontal-scroll.result_table.result_permission tbody tr td:nth-last-child(2) {
  z-index: 1;
  left: auto;
  right: auto;
}

table.horizontal-scroll.user_table tbody tr td:last-child,
table.horizontal-scroll.result_table tbody tr td:last-child,
table.horizontal-scroll.result_table tbody tr td:nth-last-child(2) {
  background: #fff;
}

table.horizontal-scroll td,
table.horizontal-scroll th {
  white-space: break-spaces;
  word-break: break-all;
}

table.horizontal-scroll tbody th {
  position: sticky;
  position: -webkit-sticky;
  /* right: 0; */

  background: #fff;
  /* z-index: 1; */
}

table.horizontal-scroll tbody tr td {
  /* if we apply postiion sticky tooltip get truncated */
  /* position: sticky; */
  /* right: 0; */

  background: #fff;
  /* z-index: 1; */
}

.zindex99 {
  z-index: 99;
}

.zindex99 .card-body {
  z-index: 99;
}

table.horizontal-scroll.user_table tbody tr td:last-child {
  text-align: center;
  position: sticky;
  position: -webkit-sticky;
}

/* ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
} 

 ::-webkit-scrollbar-thumb {
  border-radius: 1px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
} */
.data-option-container.hideDataOption:hover .data-menu {
  display: none;
}

.tenant-manage-table td {
  white-space: normal;
}

.document_file_name {
  white-space: normal;
}

.callrecord_table td.react-bs-table-no-data {
  display: block;
  width: 1125px;
}

.truncate-text {
  overflow: hidden;
  white-space: nowrap !important;
  text-overflow: ellipsis;
}

.__react_component_tooltip.type-dark.email-tooltip {
  max-width: 100% !important;
}

.__react_component_tooltip.type-dark.risk_label_tooltip {
  text-align: center;
  width: 220px !important;
}

.__react_component_tooltip.type-light.risk_label_tooltip {
  text-align: center;
  width: 220px !important;
}

.__react_component_tooltip.question_label_tooltip {
  text-align: center;
  max-width: 138px !important;
}

.__react_component_tooltip {
  word-break: break-word;
}

.tenant-img {
  width: 300px;
  height: 50px;
  object-fit: contain;
}

.participant_created_by span {
  text-transform: capitalize;
  word-break: break-word;
}

.participant_update_ts span {
  font-size: 14px;
  font-weight: 400;
}

.result_table_thead_fixed .result_table thead>tr {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 2;
}

body .reactmultiselectcheckUsertab .reactmultiselectcheckchildren__control {
  display: none;
}

.custom-tab-loader {
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-upload.disabled input {
  pointer-events: none;
  opacity: 0.6;
  /* You can adjust the opacity to make it visually disabled */
  /* Add any other styles you want for the disabled state */
}

.custom-upload[data-permission="true"] input {
  pointer-events: none;
}

.custom-upload[data-permission="true"] input:before {
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;
}

.isFileDuplicate_link {
  cursor: pointer;
  color: #1665d8;
  font-weight: 500;
}

.dropdown-open-top .select__menu {
  top: auto;
  bottom: 100%;
}

.event-disabled {
  cursor: not-allowed;
  color: #a5a5a5;
}

.event-disabled .disabled-opacity {
  opacity: 0.4;
}

.result-action-gap {
  gap: 6px;
}

.action-event.downloaded_pdf {
  background-color: #6d7278;
}

.action-event {
  background: #eeeeee;
  text-align: center;
  padding: 5px 6px;
  border: 1px solid #a1a1a1;
  border-radius: 4px;
  line-height: 0px;
  cursor: pointer;
}

.action-event.download_pdf {
  border: 1px solid #1e68d5;
  background: transparent;
}

.action-event.download_pdf.downloaded_pdf {
  background: #1e68d5;
}

.action-event.download_pdf.downloaded_pdf:hover {
  background: transparent;
}

.action-event svg {
  width: 100% !important;
}

.action-event.un_mark_review_result {
  border: 1px solid #1e68d5;
  background: transparent;
}

.action-event.mark_review_result {
  background-color: #338c1c;
}

.action-event svg.pdflinkdownload,
.action-event svg.addmitigation {
  color: #0043ff;
}

img.pdf-download-icon {
  max-height: 16px;
  margin-top: 1px;
}

img.matigation-image {
  max-width: 13.33px;
  max-height: 13.33px;
  margin-top: 2px;
}

img.support-image {
  max-width: 14px;
  max-height: 14px;
  margin-top: 1px;
}

img.play-image {
  max-width: 14px;
  max-height: 14px;
  margin-top: 45%;
}

img.mark-review-icon {
  max-height: 13px;
  max-width: 15px;
  margin-top: 2px;
}

table.horizontal-scroll.participant_table.view_access_codes_table thead th:nth-child(2) {
  left: 40px;
}

.participant-detail-modal-table.participant-table table thead tr th {
  white-space: normal;
  vertical-align: middle;
  padding-top: 12px;
}

.select__indicator.select__clear-indicator {
  cursor: pointer;
}

.schedule_last_update_time_dash {
  margin-top: -22px;
  padding-bottom: 5px;
  display: block;
}

span.scheduleLastUpdateTime {
  color: #797d89;
  font-size: 15px;
  font-weight: 400;
}

.image_icon_notification img {
  max-width: 31px;
}

p.not_accessible_page {
  text-align: center;
  margin-top: 25px;
  color: #ea3030;
}

.not-allowed-styled-none {
  cursor: not-allowed !important;
  text-decoration: none !important;
}

.custom_checkbox_componnt input[type="checkbox"]:disabled {
  cursor: not-allowed;
}

.custom_table_container {
  position: relative;
  height: 500px;
  overflow-y: auto;
}

.cs_header_check {
  margin-top: 10px;
  background-color: transparent;
  border: none;
}

.select_menu {
  height: 500px;
}

.arrow-icon {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  margin-top: 31px;
}

.business-drop-down-container {
  min-width: 16%;
  margin-right: 1.6%;
  margin-left: 1.6%;
}

.business-section-container .business-drop-down-container:first-child {
  margin-left: 0;
}

.business-section-container .business-drop-down-container:last-child {
  margin-right: 0;
}

.business-tags-container {
  min-width: 16%;
}

.filter_project_row.multiselect_filter.multiselect_use_case>div>div {
  left: auto;
  right: 0;
}

.business-line-toggle {
  display: flex;
  align-items: center;
  margin-left: 25px;
}

.business-line-toggle .MuiFormControlLabel-root {
  height: 25px;
  margin-left: 10px;
}

.off-inactive {
  color: #9ea0a5;
}

.additional_configuration_accordion .accordion__button label.off-active {
  color: #9ea0a5;
  text-decoration: none;
  font-weight: 400;
  font-size: 15px;
  font-weight: bold;
}

.additional_configuration_accordion .accordion__button label.on-active {
  font-weight: bold;
}

.additional_configuration_accordion.not-visible {
  pointer-events: none;
  opacity: 0.5;
}

button.btn-close {
  position: absolute;
  top: 20px;
  right: 15px;
  width: 18px;
  height: 18px;
  opacity: 0.7;
  cursor: pointer;
  border: none;
  background: transparent;
  transition: opacity ease 0.5s;
}

button.btn-close:hover {
  opacity: 1;
}

button.btn-close::before,
button.btn-close::after {
  content: "";
  position: absolute;
  top: 8px;
  left: 0;
  display: block;
  width: 100%;
  height: 3px;
  background: #000;
}

button.btn-close::before {
  transform: rotate(45deg);
}

button.btn-close::after {
  transform: rotate(-45deg);
}

.MuiDataGrid-columnHeaders {
  height: 48px !important;
  min-height: 48px !important;
  max-height: 48px !important;
}

.field_with_select .select-style {
  display: inline-block;
  min-width: 58px;
  min-height: 1px;
  margin: 0 7px;
}

.field_with_select .select__control {
  min-height: 1px;
}

.field_with_select .select__indicators .select__indicator.select__dropdown-indicator {
  padding: 0 8px;
}

.table_wrapper.custom_table_container.table-with-search {
  overflow: inherit;
}

.table_wrapper.custom_table_container>div>.MuiBox-root {
  position: absolute;
  top: -77px;
  right: 75px;
  z-index: 9;
  padding: 0;
}

.table_wrapper.custom_table_container>div>.MuiBox-root .MuiFormControl-root.MuiTextField-root .MuiInputBase-root.MuiInput-root:before,
.table_wrapper.custom_table_container>div>.MuiBox-root .MuiFormControl-root.MuiTextField-root .MuiInputBase-root.MuiInput-root:after {
  border: 1px solid #e4e8ee;
  height: 100%;
  border-radius: .25rem;
}

.table_wrapper.custom_table_container>div>.MuiBox-root .MuiFormControl-root.MuiTextField-root .MuiInputBase-root.MuiInput-root {
  padding: 2px 10px;
  display: flex;
}

.table_wrapper.custom_table_container>div>.MuiBox-root .MuiFormControl-root.MuiTextField-root .MuiInputBase-root.MuiInput-root>input {
  order: 1;
  margin-left: 0;
  width: 100%;
  min-width: 325px;
}

.table_wrapper.custom_table_container>div>.MuiBox-root .MuiFormControl-root.MuiTextField-root .MuiInputBase-root.MuiInput-root>svg {
  order: 2;
}

.table_wrapper.custom_table_container>div>.MuiBox-root .MuiFormControl-root.MuiTextField-root .MuiInputBase-root.MuiInput-root>button {
  display: none;
}

.datePicker__custom__header__select__wrapper {
  display: flex;
}

.MuiPaper-root .MuiCheckbox-root {
  padding: 1px 9px;
}

.data_grid_table_cell {
  word-wrap: break-word;
  white-space: break-spaces !important;
}

.first_col_head_fix,
.second_col_head_fix {
  position: sticky;
  z-index: 999;
  background-color: #f7f7f7;
}

.first_col_fix,
.second_col_fix {
  position: sticky;
  z-index: 999;
  background-color: #ffffff;
}

.last_col_head_fix,
.second_last_col_head_fix {
  position: sticky;
  z-index: 999;
  background-color: #f7f7f7;
}

.last_col_fix,
.second_last_col_fix {
  position: sticky;
  z-index: 999;
  background-color: #ffffff;
}

body .react-datepicker__navigation--previous {
  border-right-color: #ccc !important;
}

body .react-datepicker__navigation--next {
  border-left-color: #ccc !important;
}

body .react-datepicker__navigation {
  height: 10px;
  width: 10px;
  border: 0.45rem solid transparent;
}

body .MuiButton-endIcon svg.button_end_icon {
  font-size: 16px;
}

body .button_icon_only {
  font-size: 16px;
}

body .MuiCardContent-root.card-single-content {
  padding-bottom: 16px !important;
}

.card-single-content .MuiGrid-root.MuiGrid-container {
  align-items: center;
}

body .mui-button,
body .mui-button:disabled,
body .mui-button:hover {
  width: 100%;
  margin-bottom: 10px;
  box-shadow: none;
  font-size: 0.9rem;
  padding: 5px;
  line-height: 19px;
  border-radius: 0;
  color: #fff;
  cursor: pointer;
}

body .success-button,
body .success-button:disabled,
body .success-button:hover {
  background-color: #34aa44;
  border-color: #34aa44;
}

body .delete-button,
body .delete-button:disabled,
body .delete-button:hover {
  background-color: #dc3545;
  border-color: #dc3545;
}

body .button:disabled,
body .success-button:disabled,
body .delete-button:disabled {
  opacity: 0.65;
}

body .result_filter_section_new .MuiGrid-root.MuiGrid-item {
  padding: 0;
  margin-right: 6px;
}

label.hide_reviewed_irn_label {
  margin-left: 0;
}

.language-drop-down-menu .MuiMenu-paper {
  width: 100px;
}

.language-drop-down-menu .MuiMenuItem-gutters {
  display: flex;
  justify-content: center;
}

.action-container {
  text-align: center !important;
}

.multiple-select-chip .multiple-select-chip-render-cell {
  max-height: 106px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
}

body .app-mui-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

body .selected-value-chip {
  background: #E0EFFF;
  color: #0169D9;
  font-weight: 500;
}

.dark-light-text-color {
  color: #000000;
}

.placeholder-heading {
  font-weight: 600;
  font-size: 16px;
}

.placeholder-heading .light-heading {
  color: #686868;
}

.placeholder-label {
  font-weight: 400;
  color: #000000;
  font-size: 15px;
}

.field-help-text {
  font-size: 13px;
  color: #6c757d;
}