.add-tenant-row {
    margin-bottom: 25px;
    padding-right: 30px;
    margin-right: 0px;
}
.add-tenant-col {
    padding-right: 30px;
    margin-right: 0px;
}
.callcenter-manage-table .callcenter-manage-table-row {
    cursor: pointer;
}
/* form [class$="container"] {
    margin-bottom: 30px;
} */