.add-response-card {
    font-size: 14px;
}
.add-response-card code {
    color: #000;
}
.client-key-input {
    padding-right: 38px;
}
.copy-icon {
    position: absolute;
    width: 18px;
    top: 6px;
    right: 8px;
    cursor: pointer;
}