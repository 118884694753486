.resultAction-dropdown.dropdown-menu.show {
    z-index: 100;
}

body .MuiButton-root.resultAction-btn {
    background: #eaecee;
    color: #3E3F42;
    border: none;
    outline: 0;
    box-shadow: none;
    padding: 0.6rem;
    border-radius: 4px;
    min-width: 108px;
    text-transform: capitalize;
}

.resultAction-dropdown .dropdown-item:disabled {
    pointer-events: auto;
    color: #a5a5a5 !important;
    background-color: transparent !important;
}

.resultAction-dropdown .dropdown-item:hover {
    background: #f1f1f1;
    pointer-events: auto;
    cursor: pointer !important;
}

.resultAction-dropdown .dropdown-item:disabled:hover {
    background: #fff;
    pointer-events: auto;
    cursor: not-allowed !important;
}