.row-header{
    margin-left: 25px;
    margin-top: -25px;
}
.row-header-title{
    font-weight: bold;
}
.row-header-role-select-option{
    margin: 20px;
}
.row-header-role-select-header {
    margin: 0px;
    flex: 0 0 100%;
    max-width: 100%;
}
.row-header-role-select-border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.row-header-role-select-style{
    padding-left: 1.25rem;
    padding-top: 1.25rem;
}
.row-header-role-select {
    flex: 0 0 33%;
    max-width: 33%;
}
.row-header-select-description {
    margin-left: 1%;
    flex: 0 0 66%;
    max-width: 66%;
}
.permission-save-btn{
    margin: 20px;
}

.Row-head {
    display: table;
     width: 100%;
     table-layout: fixed;
     border-spacing: 7px;
     padding-left: 10px; 
} 
.permission-main-body{
    font-weight: inherit;
    color: #757575;
    margin: 20px;
}
#permission-font-style{
    font-weight: inherit;
    color: #646363;  
}
.Row-body {
    display: table;
     width: 100%;
     table-layout: fixed;
     border-spacing: 0px; 
} 

.Column-first {
     display: table-cell;
     width: 40%;
}
.Column-middle {
    display: table-cell;
    width: 15%;
    text-align: center;
}
.Column-last{
     display: table-cell;
     width: 15%;
}
.disable-accordion{
    pointer-events: none;
    opacity: 0.5;
}

