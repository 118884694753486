.login-container {
  width: 400px;
  margin: 50px auto;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  background: #fff;
  padding: 40px;
}
.login-btn {
  background: #34aa44;
  color: #fff;
  text-align: center;
  border-color: #34aa44;
}
.eye-icon {
  float: right;
  margin-left: 0px;
  margin-top: -32px;
  margin-right: 8px;
  position: relative;
}
.eye-icon.show_value {
  /* margin-top: -62px; */
}
input:-internal-autofill-selected {
  background-color: #fff !important;
  background-image: none !important;
  color: rgb(0, 0, 0) !important;
}
