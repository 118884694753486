.questions-container {
  width: 90% !important;
  max-width: 1380px !important;
}
.questions-container-height {
  height: 670px !important;
}
.questions-container .modal-body {
  overflow-y: auto;
  background-color: #fafafc;
}
.questions-header-container {
  display: flex;
  align-items: center;
}
.questions-header-container button {
  font-size: 30px;
}

.questions-title-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-right: 25px;
}
.result-irn {
  font-size: 20px;
  border-right: 3px solid #6d6e71;
  padding: 10px;
}
.result-pdf-icon {
  font-size: 2rem;
  color: #c82333;
  cursor: pointer;
}
.overall-risk-label {
  padding: 12px 16px;
  color: white;
  background: #db0003;
  margin: 0px 15px;
  border-radius: 4px;
}
.question-body-title {
  color: #3e3f42;
}
.questions-main-container {
  border: 1px solid #6d6e71;
  border-radius: 5px;
  margin: 15px 0px;
}
.questions-main-container p {
  margin: 0;
  padding: 0;
}
.questions-main-container .question-container {
  background-color: #e2e5ed;
  padding: 15px;
  border-radius: 5px 5px 0px 0px;
}

.questions-main-container .question-type {
  text-transform: capitalize;
  font-size: 17px;
  color: #383838;
}
.questions-main-container .question-text {
  font-size: 20px;
  font-weight: 500;
  color: #3e3f42;
}
.question-wrapper{
  margin-bottom: 1px solid #ddd;
}
.question-detail-container .modal-body {
  padding: 10px 10px;
}
.question-detail-container .react-bootstrap-table {
  padding-top: 0px !important;
  height: 450px;
}
.question-wrapper {
  border-bottom: 1px solid #ddd;
  padding: 15px;
  color: #727272;
  font-size: 15px;  
  background: #fff;
}
p.que-transcript {
  margin-bottom: 10px;
    margin-top: 5px;
}