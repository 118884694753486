$body_bg: #060818;
$header_bg: #060818;
$sidebar_bg: #060818;
$card_bg: #0e1726;
// $body_bg: #222736;
$black: #000000;
$white: #ffffff;
$primarybtn: #4361ee;
$secondarybtn: #1b2e4b;
$textsecondary: #c2c5d1;
$inputbg: #1b2e4b;
$footerbg: #1a1c2d;
$dark_bg: #191e3a;
$green_color: #009688;

.dark_theme {
  background: $body_bg;

  div#topbar nav {
    background: $header_bg;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
      0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  }

  .showhoverbox {
    background: $white;
    color: $black;
  }

  .showhoverbox:after {
    border-top-color: $white;
  }

  .card {
    background: $card_bg;
  }

  .main-panel {
    background: $body_bg;
  }

  .dahboardAlert-card {
    background: transparent;

    .box {
      background: $card_bg;

      .boxValue {
        color: $white;
      }
    }
  }

  .sidebar {
    background: $sidebar_bg;
    border-right: 1px solid $card_bg;
  }

  .sidebar-wrapper {
    a {
      color: #506690;

      svg {
        g {
          path {
            fill: #506690;
          }
        }
      }

      &:hover {
        background: #3b3f5c;
        color: $white !important;

        svg {
          g {
            path {
              fill: $white;
            }
          }
        }
      }
    }

    a.active {
      background-color: #364756;
      color: $white;
      border-left: 4px solid $primarybtn;

      svg {
        g {
          path {
            fill: $white;
          }
        }
      }
    }
  }

  .box span {
    color: $textsecondary;
  }

  .user-locale .dropdown-menu {
    border: solid 1px $secondarybtn;
  }

  .dropdown-menu {
    background-color: $secondarybtn;
    color: $white;
  }

  .select__indicator-separator {
    background: $textsecondary;
  }

  .select__indicator.select__dropdown-indicator svg {
    color: $textsecondary;
  }

  .btn-primary {
    background-color: $primarybtn;
    border-color: $primarybtn;
    border-radius: 0.25rem !important;
  }

  button.save-btn.button_with_loader.btn.btn-primary {
    height: auto;
  }

  .btn-secondary {
    background-color: $secondarybtn;
    border-color: $secondarybtn;
    border-radius: 0.25rem !important;
  }

  .dropdown-item:hover {
    background-color: #292c49;
  }

  .user-locale .dropdown-toggle {
    border: solid 1px $footerbg;
    background: $footerbg;
    color: $white;
  }

  .navbar-light .navbar-nav .nav-link.form-title,
  .row-header-role-select-style,
  .dropdown-item,
  .card-header,
  .card-title a,
  .admin-page-breadcrumb,
  a.admin-first-breadcrum,
  .modal-title {
    color: $white !important;
  }

  .project-stage li,
  .charttitles {
    color: $textsecondary;
  }

  .wrapper-content {
    box-shadow: 2px 6px 15px -7px #000000;

    .left {
      background: $card_bg;
    }
  }

  .form-control,
  input:-internal-autofill-selected {
    background-color: $inputbg;
    color: $white;
    border: 1px solid transparent;
    border-radius: 4px;
  }

  .form-control:focus {
    border: 1px solid #3b3f5c;
  }

  .modal-content {
    background-color: $card_bg;
  }

  .modal-header {
    border-bottom: 1px solid $dark_bg;
  }

  .text-primary {
    color: $white !important;
  }

  .close {
    text-shadow: none;
    color: #e3e4eb;
    font-weight: normal;
  }

  .footer {
    position: fixed;
    background: $footerbg;
    z-index: 9;
    left: 0;

    .copyright {
      color: $textsecondary;
    }
  }

  .card-corner.active {
    background: $primarybtn;
  }

  .project-stage li.complete:after {
    background: $primarybtn;
    border: 3px solid $primarybtn;
  }

  .dashboard_tile_card .custom-loader,
  .dashboard_tile_graph .custom-loader {
    color: $primarybtn;
  }

  .spinner-border {
    display: inline-block;
    border: 7px solid $inputbg;
    border-bottom: 7px solid $primarybtn;
    border-radius: 50%;
    border-top: 7px solid $primarybtn;
    width: 45px;
    height: 45px;
    vertical-align: text-bottom;
    border-radius: 50%;
    -webkit-animation: 1.5s linear infinite spinner-border;
    animation: 1.5s linear infinite spinner-border;
  }

  .select__menu {
    z-index: 999;
  }

  .select__menu-list {
    background: $inputbg;
    color: $white;
  }

  .select__option.select__option--is-focused,
  .select__option--is-selected,
  .reactmultiselectcheckchildren__option--is-selected,
  .reactmultiselectcheckchildren__option:hover {
    background: #292c49;
    color: $white;
  }

  .select__control,
  .basic-multi-select div {
    background: $inputbg;
    color: $white;
    border: $secondarybtn;
  }

  .internal_view_old .form-check {
    position: relative;
    z-index: 1;
  }

  .select-style {
    color: $textsecondary;
  }

  .resultsearchindex {
    z-index: 991;
  }

  .resultsearchindex-high {
    z-index: 992;
  }

  .select__single-value,
  .select-style>div:first-child>div>div,
  .select-style>div:nth-child(2)>div {
    color: $white;
  }

  .select__control--is-focused {
    box-shadow: 0 0 0 1px #3b3f5c;
  }

  .select-style>div:first-child {
    background: $inputbg;
  }

  .select-style>div:nth-child(2) {
    background: $inputbg;
  }

  .page-title,
  .page-breadcrumb,
  .page-breadcrumb a,
  .page-breadcrumb span,
  .project_filter_label label,
  .add-participant-text {
    color: $white;
  }

  .configuration-file-input::before {
    background: $green_color;
    border-color: $green_color;
  }

  button.mr-4.bluecustom-btn.btn.btn-primary {
    background: $green_color !important;
    border-color: $green_color !important;
  }

  button.btn-rounded.btn-sm.buttontoggle.btn.btn-primary {
    background: #364756;
    border-color: #364756;
  }

  a,
  .tables-control-menu {
    color: $green_color;
  }

  .react-bootstrap-table table {
    background: $card_bg;

    thead,
    th {
      background: $inputbg;
      color: $textsecondary;
      font-weight: bold;
      padding-top: 0.75rem;
      z-index: 9;
    }

    thead th {
      color: #fff;
    }
  }

  .table {
    thead {
      th {
        border-bottom: none;
      }
    }
    tbody {
      background-color: #191e3a;
    }

    td {
      border: none !important;
      color: $textsecondary;
    }

    tr:nth-of-type(odd) {
      background-color: rgba(3, 3, 5, 0.122) !important;
    }

    th {
      border: none !important;
      background: #0e1726;
      color: $textsecondary;
      font-weight: bold;
    }
  }

  .tenant-manage-table,
  .table_wrapper {
    background: $card_bg;
    box-shadow: 2px 6px 15px -7px rgba(0, 0, 0, 0.2%);

    tr {
      th {
        background: $dark_bg;
        color: $textsecondary;
        font-weight: bold;
      }

      td {
        color: $textsecondary;
        word-break: break-all;
      }
    }

    tr:nth-of-type(odd) {
      background-color: rgba(3, 3, 5, 0.122) !important;
    }
  }

  .alert-success {
    color: #1abc9c;
    background-color: rgba(26, 188, 156, 0.15);
    border-color: rgba(26, 188, 156, 0.55);

    p {
      margin-bottom: 0;
    }
  }

  .alert-danger {
    color: #e7515a;
    background-color: rgba(231, 81, 90, 0.15);
    border-color: rgba(231, 81, 90, 0.55);

    p {
      margin-bottom: 0;
    }
  }

  .project-info-tab {
    border-bottom: 1px solid $dark_bg;
    a.active:after {
      border-bottom: 2px solid $green_color;
    }
  }
  
  .tenant-manage-table-col-1,
  .tenant-manage-table-col-2 {
    border-bottom: none;
  }

  .table_wrapper_modal {
    border: 1px solid $dark_bg;

    tr {
      th {
        padding-top: 0.75rem;
      }
    }
  }

  .project-info-tab.nav>a {
    color: $textsecondary;
  }

  .project-info-tab.nav>a.active {
    color: $white;
    font-weight: bold;
  }

  .page-item.active .page-link {
    background-color: $green_color;
    border-color: $green_color;
    color: $white;
  }

  .page-item.disabled .page-link {
    background-color: $card_bg;
    border-color: $card_bg;
    cursor: not-allowed;
    pointer-events: all;
  }

  label.record_text,
  .iconmenus {
    color: $green_color;
  }

  .page-link {
    background-color: $dark_bg;
    border-color: $dark_bg;
    margin-right: 10px;
    border-radius: 4px;
    color: $textsecondary !important;
  }

  .date-picker-container:hover {
    background: $inputbg;
  }

  .data-option-container:hover .data-menu {
    background: $inputbg;
    border-color: $inputbg;
    box-shadow: 0 0 0 1px #3b3f5c;
    color: $white;

    .nav-item:hover {
      background: #292c49;
    }
  }

  form label {
    color: $textsecondary;
  }

  .react-datepicker,
  .daterangepicker,
  .daterangepicker .calendar-table {
    background: $inputbg;
    border-color: $inputbg;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
      0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  }

  .daterangepicker .calendar-table th,
  .daterangepicker .calendar-table td {
    background: $inputbg;
  }

  .daterangepicker td.in-range {
    background: rgba(64, 150, 137, 0.3);
    color: $white;
  }

  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
  .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    bottom: -1px;
    border-top-color: $inputbg;
  }

  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-bottom-color: $inputbg;
  }

  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
  .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    border-top-color: $inputbg;
  }

  .react-datepicker__header {
    background: $inputbg;
    border-bottom: 1px solid #364756;
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover {
    background: $card_bg;
    border-radius: 50%;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name,
  .daterangepicker .calendar-table {
    color: $textsecondary;
  }

  .react-datepicker__current-month,
  .daterangepicker .ranges {
    color: $white;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__day--selected {
    background: $green_color;
    color: $white;
    border-radius: 50%;
  }

  .daterangepicker td.active,
  .daterangepicker td.active:hover {
    background: $green_color;
    color: $white;
    border-radius: 5px;
  }

  .daterangepicker .ranges li.active {
    background: $green_color;
    color: $white;
    border-radius: 5px;
  }

  .react-datepicker__day.react-datepicker__day--outside-month {
    color: rgba(136, 142, 168, 0.22);
  }

  .calendar_icon {
    color: $textsecondary;
  }

  .daterangepicker .calendar-table .next span,
  .daterangepicker .calendar-table .prev span {
    border-color: #a19e9e;
  }

  .daterangepicker .ranges li:hover {
    background: $card_bg;
    border-radius: 5px;
  }

  .daterangepicker:after {
    border-bottom: 6px solid $inputbg;
  }

  .daterangepicker.show-ranges.ltr .drp-calendar.left {
    border-left: 1px solid $card_bg;
  }

  .additional_configuration_accordion {
    .accordion__button {
      color: $textsecondary;
      cursor: pointer;
      background: $inputbg;
      border-top-left-radius: 6px;
      padding: 15px;
      border-top-right-radius: 6px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      label.row-header-title {
        color: $textsecondary;
        text-decoration: none;
      }
    }

    .accordion__panel {
      border: 2px solid $inputbg;
      border-radius: 2px;
      background: transparent;
    }
  }

  .accordion__button:before {
    color: $textsecondary;
  }

  .internal_view {
    border: 2px solid $inputbg;
  }

  .custom-radio input:checked~.checkmark {
    background: $green_color;
  }

  .checkmark {
    background-color: #515365;
  }

  .participants-page .card-header {
    border-bottom: 1px solid $dark_bg;
  }

  .participants-page .add-participant-link span {
    color: $white;
  }

  .btn-success,
  .btn-success:active,
  .btn-success:focus,
  .btn-success:not(:disabled):not(.disabled).active,
  .btn-success:not(:disabled):not(.disabled):active,
  .show>.btn-success.dropdown-toggle {
    background: $green_color;
    border-color: $green_color;
    box-shadow: none;
  }

  .btn-danger {
    background-color: #e7515a;
    border-color: #e7515a;
  }

  .btn {
    border-radius: 4px;
  }

  .result-filter-label {
    color: $textsecondary;
  }

  ._loading_overlay_overlay {
    background: $card_bg;
  }

  .question-list-row,
  .add-new-question {
    background: $card_bg;
    color: $textsecondary;
  }

  p.questionpara pre {
    color: $textsecondary;
  }

  .ques-text textarea {
    background: $inputbg;
    border-color: transparent;
    border-radius: 4px;
    color: $white;
  }

  .ques-text textarea:focus {
    box-shadow: none;
    outline: none;
  }

  .text-color {
    color: $textsecondary;
  }

  .form-check input {
    padding: 0;
    height: 23px;
    width: 16px;
    cursor: pointer;
    position: absolute;
    z-index: 99;
    left: 1px;
    opacity: 0;
    margin: 0;
    top: 2px;
  }

  .form-check .form-check-label {
    cursor: pointer;
  }

  .form-check .form-check-label:before {
    content: "";
    border: 2px solid #515365;
    padding: 7px;
    cursor: pointer;
    position: absolute;
    left: 0;
    z-index: 9;
    background: #515365;
    border-radius: 4px;
    top: 5px;
  }

  .form-check input:checked+.form-check-label:before {
    border: 2px solid $green_color;
    background: $green_color;
  }

  .form-check input:checked+.form-check-label:after {
    content: "";
    position: absolute;
    top: 7px;
    left: 6px;
    width: 6px;
    height: 11px;
    border: 2px solid #ffffff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    z-index: 9;
  }

  .dashboard_doghnut_center_text {
    color: $textsecondary;
  }

  .payloaddata pre {
    color: $textsecondary;
  }

  .accordion__button {
    background: $inputbg;
    color: $textsecondary;
  }

  .card.row-header-role-select,
  .card.row-header-select-description {
    background: transparent;
    border: 1px solid $secondarybtn;
  }

  .row-header-role-select-border {
    border-bottom: 1px solid $secondarybtn;
  }

  .accordion__panel {
    background: transparent;
    border: 2px solid $secondarybtn;
  }

  .Column-first label,
  .Column-middle label {
    color: $textsecondary;
  }

  .tenant-add-button {
    color: $green_color;
  }

  .tenant-add-button svg path {
    fill: $green_color;
  }

  .question-pertinent {
    margin: 0 auto;
    max-width: 20px;
  }

  .ques-expanswer span.underline {
    border-bottom: none;
  }

  .checkmark {
    height: 18px;
    width: 18px;
  }

  .custom-radio {
    margin-right: 5px;
  }

  .modal-box-text {
    color: $white;
  }

  .modal-backdrop {
    background-color: rgba(27, 46, 75, 0.8);
    z-index: 10401;
  }

  .modal {
    z-index: 10501;
  }

  .ques-sequence.active {
    background: $green_color;
  }

  .approvedetail {
    background: $inputbg;
    color: $textsecondary;
    border-radius: 4px;
  }

  .modal-body {
    color: $textsecondary;
    background: $card_bg;
  }

  .question-body-title {
    color: $white;
  }

  .questions-main-container {
    background: $header_bg;
    border: 1px solid $header_bg;

    .question-container {
      background: #2c3e52;
    }

    .question-type {
      color: $white;
    }

    .question-text,
    p.que-transcript {
      color: $textsecondary;
    }

    .question-wrapper {
      background: transparent;
      border-bottom: 1px solid #1b2e4c;
    }
  }

  .resultAction-btn.btn-primary,
  .resultAction-btn.btn-primary:hover,
  .resultAction-btn.btn-primary:active,
  .resultAction-btn.btn-primary:focus {
    background: #1b2e4c !important;
    color: #ffffff !important;
  }

  .email-wrapper {
    border: 1px solid $secondarybtn;
  }

  .rdw-editor-toolbar {
    background: $inputbg;
    color: $textsecondary;
    border-color: $secondarybtn;
  }

  .DraftEditor-editorContainer span {
    background: transparent !important;
    color: $textsecondary !important;
  }

  .helper-box {
    background: $inputbg;
    color: $textsecondary;
    border-left: 6px solid $green_color;
  }

  .small,
  small,
  .data-option-container .data-item span,
  .data-option-container .data-control span {
    color: $textsecondary;
  }

  .react-bootstrap-table-pagination button#pageDropDown {
    background: $inputbg !important;
    border-color: $inputbg !important;
  }

  ::-webkit-input-placeholder {
    /* Edge */
    color: $textsecondary;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $textsecondary;
  }

  ::placeholder {
    color: $textsecondary;
  }

  .form-check.custom_checkbox_componnt {
    margin: 0 auto;
    max-width: 20px;
  }

  .filter_project_row button {
    background: $inputbg;
    border-color: $inputbg;
    color: $white;
  }

  .reactmultiselectcheck {
    background: $inputbg;
    color: $textsecondary;
  }

  .reactmultiselectcheckchildren__control {
    background: $card_bg;
    border-color: $card_bg;
  }

  .template-preview {
    color: $white;
    background: transparent;
  }

  .template-section {
    background: transparent;
    border: 1px solid $inputbg;

    span {
      color: $textsecondary !important;
      background: transparent !important;
    }
  }

  .template-section-sms {
    background: transparent;
    border: 1px solid $inputbg;
    min-height: 200px;

    span {
      color: $textsecondary !important;
      background: transparent !important;
    }
  }

  .select__value-container>div {
    color: $white;
  }

  .tableicon {
    color: $textsecondary;
  }

  ::-webkit-scrollbar {
    width: 11px;
  }

  ::-webkit-scrollbar-track {
    background: $dark_bg;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $inputbg;
    border-radius: 6px;
    border: 3px solid $inputbg;
  }

  .form-title {
    color: $white;
  }

  .table_wrapper .document_file_name {
    color: $green_color;
    cursor: pointer;
  }

  .img-thumbnail {
    background: $inputbg;
    border-color: $inputbg;
  }

  .card-code {
    background: $inputbg;

    pre {
      color: $textsecondary;
    }
  }

  .reactmultiselectcheckchildren__input {
    color: $white;
  }

  .tenant-table .react-bootstrap-table .table,
  .tenant-manage-table {
    background: $card_bg;
  }

  .about-content-box,
  .email-notify,
  .email-notify label.form-check-label {
    color: $textsecondary;
  }

  .select__multi-value {
    background: $footerbg;

    .select__multi-value__label {
      color: $textsecondary;
    }
  }

  .dropdown-toggle:after {
    display: none;
  }

  .freshWork-container a {
    background: #1a1c2d;
    color: #fff;
  }

  .participant.select-all-dropdown.dropdown-menu,
  .participant.select-all-dropdown .dropdown-menu {
    background: $inputbg;
  }

  table.horizontal-scroll.result_table,
  table.horizontal-scroll.participant_table,
  table.horizontal-scroll.callrecord_table {
    tbody {
      tr:nth-of-type(odd) {

        td:nth-last-child(2),
        td:last-child {
          background: #0c131e;
        }
      }

      tr:nth-of-type(even) {

        td:nth-last-child(2),
        td:last-child,
        td:first-child,
        td:nth-child(2),
        td:nth-child(3),
        td:nth-child(4) {
          background: $card_bg;
        }
      }
    }

    thead {

      th:last-child,
      th:nth-last-child(2) {
        background: $dark_bg;
      }
    }
  }

  table.horizontal-scroll.callrecord_table {
    tbody {
      tr:nth-of-type(even) {
        td:first-child {
          background: $card_bg;
        }
      }
    }
  }

  table.horizontal-scroll.user_table {
    thead {
      th:last-child {
        background: $dark_bg;
      }
    }

    tbody {
      tr {
        &:nth-of-type(even) {
          td:last-child {
            background: $card_bg;
          }
        }

        td:last-child {
          text-align: center;
        }
      }
    }
  }

  table.horizontal-scroll {
    tbody {
      th {
        background: inherit;
      }

      tr:nth-of-type(odd) td {
        background: #0c131e;

      }

      tr {
        td {
          background-color: $card_bg;
        }
      }
    }

    thead th:first-child {
      background: $dark_bg;
    }
  }

  span.result_resetfilter,
  span.custom-daterange {
    color: $white;
  }

  span.custom-daterange {
    background: $inputbg;
    border: none;
  }

  .input-group-text {
    background-color: $primarybtn;
    border: none;
    color: $white;
  }

  .bluecustom-btn.dtheme_btn {
    background: $green_color !important;
    border-color: $green_color !important;
  }

  .nav-tabs {
    .nav-link {
      color: $white;

      &.active {
        background: $dark_bg;
        border-bottom: 2px solid $green_color !important;
      }
    }
  }

  .result-note-msg textarea {
    background: $inputbg;
    border-color: $inputbg;
    color: $white;
  }

  .accordion__panel textarea.form-control {
    color: $white;
  }

  .multiselect-style,
  .filter_project_row.multiselect_filter>div>div>div>div>div div,
  .filter_project_row.multiselect_filter>div>div>div>div {
    background: $inputbg !important;
    color: #fff;
  }

  .filter_project_row.multiselect_filter>div>div>div>div>div div:hover {
    background: #292c49 !important;
  }

  .select--is-disabled {
    cursor: not-allowed !important;
    pointer-events: auto;
  }

  .select__control.select__control--is-disabled.css-1fhf3k1-control {
    background: #545454 !important;
    cursor: not-allowed !important;
  }

  .form-control:disabled,
  .form-control[readonly] {
    background-color: #545454 !important;
    opacity: 1;
    cursor: not-allowed;
  }

  .react-tel-input {
    .flag-dropdown.open .selected-flag {
      background: $inputbg;
    }

    .flag-dropdown {
      background-color: $inputbg;
      border: 1px solid $inputbg;
      border-right: 1px solid $white;
    }

    .selected-flag:hover,
    .selected-flag:focus {
      background: $card_bg;
    }

    .form-control {
      border: 1px solid $inputbg;
    }

    .country-list {
      background-color: $inputbg;
      color: $white;

      .country:hover {
        background-color: $card_bg;
      }

      .country .dial-code {
        color: $white;
      }
    }
  }

  h5.email_notify_text {
    background: $card_bg;
    color: $white;
  }

  .email-notify {
    border: 1px solid #364756;
  }

  .label-withline_text {
    color: $white;
    background: $card_bg;
  }

  .label-withline h6 {
    color: $textsecondary;
  }

  span.scheduleLastUpdateTime {
    color: #9a9eab;
  }

  .review-modal-text {
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
  }

  .cancel_button {
    color: #ffffff;
  }

  .review-modal-title {
    color: #1e68d5 !important;
  }

  .form-check input:disabled+.form-check-label:before {
    border: 2px solid $textsecondary;
    background: $textsecondary;
  }

  .MuiSelect-multiple span.select__placeholder {
    color: #ffffff;
  }

  .MuiSelect-multiple {
    background: #1b2e4b;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .react-datepicker-popper svg.react-datepicker__triangle {
    display: none;
  }

  .MuiList-root.MuiMenu-list {
    background: #1b2e4b;

    .MuiMenuItem-root {
      color: #ffffff;
    }

    .MuiListItemText-root {
      color: #ffffff;
    }

    .dropdown_template_risk_level_flag span {
      color: #ffffff;
    }

    .dropdown_template_risk_level_flag span {
      color: #ffffff;
    }
  }

  select.datePicker__custom__header__select {
    background: #1b2e4b;
    color: #fff;
  }

  .MuiCheckbox-root {
    svg {
      fill: #ffffff;
    }
  }

  .custom-datePicker {
    color: #ffffff;
    background: #1b2e4b;
    border: none;
  }

  .hide_reviewed_irn_label span.MuiTypography-root {
    color: #A1A1A1;
  }

  .MuiInputBase-root.MuiOutlinedInput-root svg {
    color: #fff;
  }

  span.MuiSwitch-track {
    background: #c7c7c7;
  }

  // Table Css start here 
  .MuiDataGrid-columnHeaders {
    background-color: #0e1726;
    color: #fff;
  }


  .MuiTablePagination-selectLabel,
  .MuiTablePagination-displayedRows,
  .MuiPaginationItem-root,
  .MuiSelect-standard,
  .MuiSelect-icon.MuiTablePagination-selectIcon {
    color: #fff;
  }

  .MuiDataGrid-cell {
    color: #c2c5d1
  }


  .MuiDataGrid-withBorderColor {
    border-color: rgb(224 224 224 / 9%);
  }

  .MuiPaginationItem-root.Mui-selected {
    background-color: #1b2e4b;
  }

  .MuiPaginationItem-root:hover {
    background-color: rgb(27 46 75 / 50%);
  }

  .MuiTableFooter-root {
    background-color: #191e3a;
  }

  .custom-overlay {
    color: #ffffff;
  }

  button.btn-close::before,
  button.btn-close::after {
    background: #ffffff;
  }
  .first_col_fix, 
  .second_col_fix {
    position: sticky;
    z-index: 999;
    background-color: #191e3a;
}
.last_col_fix,
.second_last_col_fix {
  position: sticky;
  z-index: 999;
  background-color: #191e3a;
}
.MuiPaper-root .MuiCardContent-root {
  background: #0e1726;
  color: #fff;
}

.MuiPaper-root .MuiCardHeader-root {
  background: #0e1726;
  color: #fff;
}
.tile_card  {
  background: #0e1726;
}
.MuiGrid2-root .boxContent .boxValue {
  color: #fff;
}
.MuiButton-root.Mui-disabled {
  color: #fff;
  box-shadow: none;
  background-color: #4361ee;
  opacity: 0.8;
}

.success-button,
.success-button:disabled,
.success-button:hover {
  background-color: #34aa44;
  border-color: #34aa44;
}

.delete-button,
.delete-button:disabled,
.delete-button:hover {
  background-color: #dc3545;
  border-color: #dc3545;
}

.button:disabled,
.success-button:disabled,
.delete-button:disabled {
  opacity: 0.65;
}

.MuiButton-root.resultAction-btn {
  background: #1b2e4c;
  color: #fff;
}

.MuiDataGrid-root .MuiDataGrid-withBorderColor{
  border: none;
}

.MuiButtonBase-root.MuiTab-root.Mui-selected {
  color: #1976d2;
}

.MuiButtonBase-root.MuiTab-root {
  color: #fff;
}

.MuiTableContainer-root.paper-table-container {
  background: rgb(23 27 53);
}

.MuiDataGrid-overlayWrapper .MuiDataGrid-overlayWrapperInner .MuiDataGrid-overlay  {
  background: rgb(23 27 53);
}

pre {
  color: #fff;
  overflow: hidden;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: 500;
  text-transform: uppercase;
  color: #c2c5d1;
}

.MuiDialog-paperScrollPaper,
.template_type_input #template_name,
.stepper-dialog-title,
.stepper-dialog-actions,
.popover-container,
.date-range-section {
  background: #0e1726 !important;
}

.dark-light-text-color,
.date-range-section .custom-datePicker-value,
.MuiStepLabel-label.Mui-completed,
.MuiStepLabel-label.Mui-active,
.custom-step-icon div div,
.attributes-select-deselect span.MuiTypography-root,
.attributes-selection span.MuiTypography-root {
  color: #fff;
}

.MuiStepLabel-label {
  color: #757575;
}

.attributes-select-deselect span.MuiTypography-root {
  font-weight: 700;
  font-size: 16px;
}

.attributes-selection span.MuiTypography-root {
  font-weight: 400;
  font-size: 16px;
}


.date-range-section .date-picker-container {
  background: transparent;
}
.date-range-section .calendar_icon_wrapper svg {
  color: #fff;
}    
.timezone-field .basic-multi-select {
  background: #1b2e4b;
  color: #4f3535;
  border: #1b2e4b;
}
.timezone-field .basic-multi-select div {
  color: #fff;
  background: transparent;
  border: none;
}
.Toastify__toast-container {
  z-index: 99999;
}
.report_search {
  background-color: #1b2e4b;
  color: #ffffff;
  border: 1px solid transparent;
  border-radius: 4px;
}

.refresh_icon_color {
  color: #ffffff;
}

}